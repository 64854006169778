import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import styles from './StudyResults.module.scss';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import APIClient from '../../../../../util/APIClient';
import { exportToCsv, exportToJson } from '../../../../../util/exportZip';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import Form from '../../../Common/Form/Form';
import StudyResultsForm from './StudyResultsForm/StudyResultsForm';

export default function StudyResults() {
  const { studyId } = useParams();
  const [formAlert, setFormAlert] = React.useState(null);
  const { data, error, isLoading } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });

  if (isLoading) {
    return <Loading message="Loading..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  async function getStudyResults({ includeParticipantDetails }) {
    const response = await APIClient.get(
      `/researcher/studies/${studyId}/questionnaires/-/submissions`,
      {
        params: {
          includeParticipantDetails: !!includeParticipantDetails,
          includeQuestionnaires: true,
          includeNotifications: true,
        },
      }
    );

    return response.data;
  }

  async function onSubmit(formData) {
    try {
      setFormAlert({
        severity: 'info',
        title: 'Preparing your data extract!',
        message:
          'Your study data is being fetched from the server and assembled into a bundle.',
      });
      const results = await getStudyResults(formData);
      switch (formData.format.toLowerCase()) {
        case 'csv':
          await exportToCsv(results);
          break;
        case 'json':
          await exportToJson(results);
          break;
        default:
          await exportToJson(results);
      }
      setFormAlert({
        severity: 'success',
        title: 'Study data exported successfully!',
        message:
          'Your study data has been exported successfully. The file download should start automatically.',
      });
    } catch (e) {
      setFormAlert({
        severity: 'error',
        title: 'Could not export the study data!',
        message:
          'Something went wrong when requesting study data export. Please contact the administrator for further support.',
      });
    }
  }

  return (
    <div className={styles.studyResults}>
      <div className={styles.header}>
        <h1>Export study results</h1>
      </div>
      <p>
        Use the form below to adjust the data that should be included in the
        study data export. Keep in mind that regardless of the check-boxes you
        select, the data dump will be a .zip file containing the info.txt file,
        and the download will start automatically.
      </p>
      <Form onSubmit={(formData) => onSubmit(formData)}>
        <StudyResultsForm />
        <div className={styles.formControls}>
          <Button variant="contained" type="submit" fullWidth>
            Create
          </Button>
        </div>
        {formAlert && (
          <div className={styles.alert}>
            <Alert severity={formAlert.severity}>
              {formAlert.title && <AlertTitle>{formAlert.title}</AlertTitle>}
              {formAlert.message}
            </Alert>
          </div>
        )}
      </Form>
    </div>
  );
}
