import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from './QuestionnaireForm.module.scss';
import Question from './Question/Question';
import useQuestionnaire from '../../../../../../hooks/useQuestionnaire';
import FormTextField from '../../../../Common/FormTextField/FormTextField';
import QuestionnairePrompts from './QuestionnairePrompts/QuestionnairePrompts';
import { fieldCountValidator } from '../../../../../../util/FormValidatorFunctions';
import FormCustomValidationGroup from '../../../../Common/FormCustomValidationGroup/FormCustomValidationGroup';
import FormSelect from '../../../../Common/FormSelect/FormSelect';
import FormCheckbox from '../../../../Common/FormCheckbox/FormCheckbox';

export default function QuestionnaireForm() {
  const { setValue, getValues } = useFormContext();
  const { questions, addQuestion } = useQuestionnaire();
  const rootQuestionOrderIdOptions = questions.map((question) => ({
    key: `rootQuestionOrderIdOption-question-${question.id}`,
    value: question.id,
    label: question.localIdentifier,
  }));
  const defaultRootQuestionOrderIdOption =
    rootQuestionOrderIdOptions.length >= 0 ? rootQuestionOrderIdOptions[0] : '';
  const memoisedQuestions = React.useMemo(() => questions, [questions]);

  const watchedRootQuestionOrderId = useWatch({
    name: `rootQuestionOrderId`,
    defaultValue: defaultRootQuestionOrderIdOption
      ? defaultRootQuestionOrderIdOption.value
      : '',
  });

  React.useEffect(() => {
    if (
      questions.length === 0 ||
      rootQuestionOrderIdOptions.filter(
        (option) => option.value === watchedRootQuestionOrderId
      ).length === 0
    ) {
      setValue('rootQuestionOrderId', '');
    }
  }, [
    watchedRootQuestionOrderId,
    setValue,
    questions,
    rootQuestionOrderIdOptions,
  ]);

  return (
    <>
      <div className={styles.questionnaireMeta}>
        <FormLabel>Questionnaire information</FormLabel>
        <FormTextField
          name="name"
          label="Name"
          defaultHelperText="Friendly name that identifies this questionnaire amongst others."
          rules={{
            required: {
              value: true,
              message: 'Questionnaire name is required.',
            },
          }}
        />
        <FormTextField
          name="description"
          label="Description"
          defaultHelperText="Description of the questionnaire contents."
          rules={{
            required: {
              value: true,
              message: 'Questionnaire description is required.',
            },
          }}
        />
        <FormCheckbox
          name="allowEventDriven"
          label="Event driven submissions"
          defaultHelperText="Allow users to submit this questionnaire in an event-driven mode. Checking this box will display the questionnaire in list of available questionnaires and allow users to submit it without being prompted by notification. You will still be able to send the questionnaire on schedule."
          defaultValue
        />
      </div>
      <QuestionnairePrompts />
      <FormLabel>Questions</FormLabel>
      <FormHelperText>
        Questions can be created, removed and assigned different types. Once
        created, the <em>&quot;Next question&quot;</em> field can be used to
        establish order of the questionnaire and, in the case of{' '}
        <em>&quot;predefined answers&quot;</em>, branches of the user journey
        through the questionnaire, depending on the answers they select. The
        local identifier can be used to easily identify different questions
        during the linking process, and will not be visible to the participants.
      </FormHelperText>

      <FormSelect
        name="rootQuestionOrderId"
        variant="outlined"
        defaultHelperText="First question that will be asked in a questionnaire."
        label="Starting question"
        options={rootQuestionOrderIdOptions}
        defaultOption={defaultRootQuestionOrderIdOption}
        rules={{
          required: {
            value: true,
            message: 'The first question has to be specified.',
          },
        }}
      />
      <FormCustomValidationGroup
        className={styles.questionsValidator}
        name="questionsValidator"
        customValidatorError="At least one question is required for the questionnaire."
        customValidator={() =>
          fieldCountValidator(
            memoisedQuestions.map((question) => `questions.${question.id}`),
            1
          )
        }
        fieldsToWatch={[]}
      >
        {/* TODO: This is very performance-intensive since all fields of all questions are being
              checked each time any change is made to the form. Rather than this, the validation
              should be deferred until submission, or the selection of fields that are being
              watched should be restricted to the questions.{id}.nextQuestionOrderId and
              rootQuestionOrderId. The reason why this is not implemented like so yet is due
              to the error where dependency array for useEffect in FormCustomValidationGroup
              component changes size, as questions are added (and thus fieldsToWatch changes size).
        */}
        <FormCustomValidationGroup
          className={styles.questionsValidator}
          name="questionsConnectednessValidator"
          customValidatorError="All questions have to be linked to by at least one other question. There seem to be question(s) that are not pointed to by any other question or answer."
          customValidator={() => {
            const nextQuestionOrderIdFields = memoisedQuestions
              .map((question) => {
                const answersNextQuestionOrderIdFields = question.answers.map(
                  (answer) =>
                    `questions.${question.id}.answers.${answer.id}.nextQuestionOrderId`
                );
                return [
                  `questions.${question.id}.nextQuestionOrderId`,
                  ...answersNextQuestionOrderIdFields,
                ];
              })
              .flat();
            const nextQuestionOrderIds = getValues(nextQuestionOrderIdFields);
            const linkedQuestionIds = [
              watchedRootQuestionOrderId,
              ...nextQuestionOrderIds,
            ];
            const unlinkedQuestionIds = memoisedQuestions
              .map((question) => question.id)
              .filter((questionId) => !linkedQuestionIds.includes(questionId));
            return unlinkedQuestionIds.length <= 0;
          }}
          watchFieldsSilently
          fieldsToWatch={['questions', 'rootQuestionOrderId']}
        >
          <div className={styles.questions}>
            {memoisedQuestions.map((question, index, array) => (
              <Question
                key={`question-${question.id}`}
                question={question}
                orderNumber={`${index}`}
                isFirstQuestion={index === 0}
                isLastQuestion={index === array.length - 1}
              />
            ))}
          </div>
        </FormCustomValidationGroup>
      </FormCustomValidationGroup>
      <div className={styles.addQuestion}>
        <Button
          type="button"
          variant="outlined"
          onClick={() => addQuestion()}
          startIcon={<AddIcon />}
        >
          Add new question
        </Button>
      </div>
    </>
  );
}
