import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function StudySchedules() {
  const navigate = useNavigate();
  const { studyId, questionnaireId } = useParams();

  const {
    data: studyData,
    error: studyError,
    isLoading: studyIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });
  const {
    data: schedulesData,
    error: schedulesError,
    isLoading: schedulesIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules`,
  });

  function createNewSchedule() {
    navigate('create');
  }

  if (studyIsLoading || schedulesIsLoading) {
    return <Loading message="Fetching schedules..." />;
  }

  if (
    studyError ||
    schedulesError ||
    studyData === null ||
    schedulesData === null
  ) {
    return getErrorComponentFromHttpError(studyError || schedulesError);
  }

  return (
    <Table
      rows={schedulesData}
      topButtons={[
        {
          label: 'Create schedule',
          handler:
            studyData.stage === 'FINISHED'
              ? null
              : () => {
                  createNewSchedule();
                },
        },
      ]}
      title="Schedules"
      rowLinks={{
        name: {
          label: 'View schedule',
          to: (id) =>
            `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules/${id}`,
        },
      }}
      columns={[
        {
          id: 'name',
          label: 'Schedule name',
          numeric: false,
        },
        {
          id: 'createdAt',
          label: 'Created on',
          numeric: true,
          date: true,
        },
      ]}
    />
  );
}
