import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import APIClient from '../../../../../util/APIClient';
import styles from './CreateQuestionnaire.module.scss';
import Form from '../../../Common/Form/Form';
import QuestionnaireForm from './QuestionnaireForm/QuestionnaireForm';
import {
  QuestionnaireProvider,
  END_QUESTIONNAIRE_VALUE,
} from '../../../../../hooks/useQuestionnaire';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';

const NUMERICAL_PROPERTIES = {
  question: ['numberConstraintsMin', 'numberConstraintsMax', 'orderNumber'],
};

const WHITELISTED_PROPERTIES = {
  question: [
    'orderId',
    'orderNumber',
    'prompt',
    'placeholder',
    'numberConstraintsMin',
    'numberConstraintsMax',
    'nextQuestionOrderId',
    'type',
  ],
  notificationPrompt: ['title', 'body'],
  answer: ['content', 'orderNumber', 'nextQuestionOrderId'],
};

function stripNonWhitelistedProperties(object, whitelist) {
  const newObject = {};
  Object.keys(object)
    .filter((key) => whitelist.includes(key))
    .forEach((key) => {
      newObject[key] = object[key];
    });
  return newObject;
}

function stripEmptyNumericalProperties(object, propertyList) {
  const newObject = object;
  propertyList.forEach((property) => {
    if (Object.prototype.hasOwnProperty.call(newObject, property)) {
      if (newObject[property] === '') {
        delete newObject[property];
      }
    }
  });
  return newObject;
}

function convertObjectToArrayWithKeyAs(object, keyFieldName) {
  const keys = Object.keys(object);
  return keys.map((key) => {
    const result = { ...object[key] };
    if (keyFieldName) {
      result[keyFieldName] = key;
    }
    return result;
  });
}

function replaceNextQuestionOrderId(object) {
  if (Object.prototype.hasOwnProperty.call(object, 'nextQuestionOrderId')) {
    if (
      !object.nextQuestionOrderId ||
      object.nextQuestionOrderId === END_QUESTIONNAIRE_VALUE
    ) {
      object.nextQuestionOrderId = null;
    }
  }
  return object;
}

function formatQuestionnaireData(data) {
  let questions = convertObjectToArrayWithKeyAs(data.questions, 'orderId');
  questions = questions.map((question) =>
    replaceNextQuestionOrderId({
      ...question,
      answers: Object.prototype.hasOwnProperty.call(question, 'answers')
        ? convertObjectToArrayWithKeyAs(question.answers)
        : [],
    })
  );

  questions = questions.map((question) => ({
    ...stripEmptyNumericalProperties(
      stripNonWhitelistedProperties(question, WHITELISTED_PROPERTIES.question),
      NUMERICAL_PROPERTIES.question
    ),
    answers: question.answers.map((answer) =>
      replaceNextQuestionOrderId(
        stripNonWhitelistedProperties(answer, WHITELISTED_PROPERTIES.answer)
      )
    ),
  }));

  const notificationPrompts = convertObjectToArrayWithKeyAs(
    data.notificationPrompts
  ).map((prompt) =>
    stripNonWhitelistedProperties(
      prompt,
      WHITELISTED_PROPERTIES.notificationPrompt
    )
  );

  return {
    name: data.name,
    description: data.description,
    rootQuestionOrderId: data.rootQuestionOrderId,
    allowEventDriven: data.allowEventDriven,
    questions,
    notificationPrompts,
  };
}

export default function CreateQuestionnaire() {
  const { studyId } = useParams();
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = React.useState(null);
  const { data: study, isLoading } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });

  React.useEffect(() => {
    if (study) {
      if (study.stage && study.stage === 'FINISHED') {
        navigate(`/researcher/studies/${studyId}`);
      }
    }
  }, [study, navigate, studyId]);

  if (isLoading) {
    return <Loading />;
  }

  async function onSubmit(data) {
    try {
      await APIClient.post(
        `/researcher/studies/${studyId}/questionnaires`,
        formatQuestionnaireData(data)
      );
      setFormAlert({
        severity: 'success',
        title: 'Questionnaire created!',
        message: 'Your questionnaire has been created successfully.',
      });
    } catch (e) {
      setFormAlert({
        severity: 'error',
        title: 'Could not create questionnaire!',
        message:
          'Something went wrong during questionnaire creation. Please contact the administrator for further support.',
      });
    }
  }

  return (
    <div className={styles.createQuestionnaire}>
      <p className={styles.description}>
        Create a new questionnaire for the selected study using the form below.
        The created questionnaire can be used multiple times with several
        different schedules, but the set of question and notification prompts
        will remain the same.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <QuestionnaireProvider>
          <QuestionnaireForm />
        </QuestionnaireProvider>
        <div className={styles.formControls}>
          <Button variant="contained" type="submit" fullWidth>
            Create
          </Button>
        </div>
        {formAlert && (
          <div className={styles.alert}>
            <Alert severity={formAlert.severity}>
              {formAlert.title && <AlertTitle>{formAlert.title}</AlertTitle>}
              {formAlert.message}
            </Alert>
          </div>
        )}
      </Form>
    </div>
  );
}
