import React from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import styles from './NotificationPrompt.module.scss';

function getRandomTimeString() {
  const randomDate = new Date(Math.random() * 86400000);
  const ampm = Math.random() > 0.75 ? 'am' : 'pm';
  return `${(randomDate.getHours() % 12) + 1}:${
    randomDate.getMinutes() < 10
      ? `0${randomDate.getMinutes()}`
      : randomDate.getMinutes()
  } ${ampm}`;
}

export default function NotificationPrompt(props) {
  const { notificationPrompt } = props;
  const time = getRandomTimeString();

  return (
    <div className={styles.notificationPrompt}>
      <div className={styles.topRow}>
        <AppsIcon className={styles.icon} />
        <h1>Mesmerise</h1>
        <div className={styles.spacer} />
        <p>{time}</p>
      </div>
      <div className={styles.contentRow}>
        <h2>{notificationPrompt.title}</h2>
        <p>{notificationPrompt.body}</p>
      </div>
    </div>
  );
}
