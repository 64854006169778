import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Table from '../../../../Common/Table/Table/Table';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import APIClient from '../../../../../../util/APIClient';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';

export default function StudyResearchers() {
  const navigate = useNavigate();
  const { studyId } = useParams();
  const { data, error, isLoading, reload } = useApiData({
    path: `/researcher/studies/${studyId}/researchers`,
  });

  async function makeUsersLead(userIds) {
    await APIClient.put(`/researcher/studies/${studyId}/researchers`, {
      userIds,
      operation: 'makeLead',
    });
    reload();
  }

  async function makeUsersNonLead(userIds) {
    await APIClient.put(`/researcher/studies/${studyId}/researchers`, {
      userIds,
      operation: 'makeNonLead',
    });
    reload();
  }

  async function removeUsersFromStudy(userIds) {
    await APIClient.delete(`/researcher/studies/${studyId}/researchers`, {
      data: {
        userIds,
      },
    });
    reload();
  }

  function inviteResearcher() {
    navigate('invite');
  }

  if (isLoading) {
    return <Loading message="Fetching researchers..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const displayedData = data.map((researcher) => ({
    ...researcher.user,
    isLead: researcher.isLead,
  }));

  return (
    <Table
      rows={displayedData}
      topButtons={[
        {
          label: 'Invite researcher',
          handler: () => {
            inviteResearcher();
          },
        },
      ]}
      title="Researchers"
      allowSelect
      selectedActions={[
        {
          handler: (userIds) => makeUsersLead(userIds),
          label: 'Make lead',
          icon: <AddModeratorIcon />,
        },
        {
          handler: (userIds) => makeUsersNonLead(userIds),
          label: 'Make non-lead',
          icon: <RemoveModeratorIcon />,
        },
        {
          handler: (userIds) => removeUsersFromStudy(userIds),
          label: 'Remove from study',
          icon: <PersonRemoveIcon />,
          clearSelectionAfterAction: true,
        },
      ]}
      columns={[
        {
          id: 'givenName',
          label: 'First name',
          numeric: false,
        },
        {
          id: 'familyName',
          label: 'Last name',
          numeric: false,
        },
        {
          id: 'email',
          label: 'E-mail address',
          numeric: false,
        },
        {
          id: 'isLead',
          label: 'Lead researcher',
          boolean: true,
        },
      ]}
    />
  );
}
