import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import HourCheckbox from './HourCheckbox/HourCheckbox';
import styles from './HourMatrix.module.scss';

import {
  DAYS_OF_THE_WEEK,
  DEFAULT_ACTIVE_DAYS_OPTION_VALUE,
  DEFAULT_ACTIVE_HOURS_OPTION_VALUE,
  DEFAULT_DATE_FROM,
  DEFAULT_DATE_TO,
} from '../DefaultFormValues';
import SchedulePreview from '../SchedulePreview/SchedulePreview';

export function getDayHourName(day, hour) {
  return `${day}Hour${hour}`;
}

export function getHoursArray() {
  return Array.from({ length: 24 }, (_) => false);
}

export function assembleMatrix(data, studyDuration) {
  const isPattern = data.scheduleType === 'REPEATED_PATTERN';
  const matrix = Array.from({ length: studyDuration }, () =>
    Array.from({ length: 24 }, () => null)
  );
  if (isPattern) {
    const patternDuration = Number.parseInt(data.repeatedPatternDuration, 10);
    const pattern = Array.from({ length: patternDuration }, (_, day) =>
      Array.from(
        { length: 24 },
        (__, hour) => data[getDayHourName(`day${day}`, hour)]
      )
    );

    matrix.forEach((_, day) => {
      for (let hour = 0; hour < 24; hour += 1) {
        matrix[day][hour] = pattern[day % patternDuration][hour];
      }
    });
  } else {
    matrix.forEach((_, day) => {
      for (let hour = 0; hour < 24; hour += 1) {
        matrix[day][hour] = data[getDayHourName(`day${day}`, hour)];
      }
    });
  }
  return matrix;
}

function recalculateMatrix(
  activeDays,
  activeHours,
  activeHoursFrom,
  activeHoursTo,
  currentFields,
  days
) {
  // Get values from the selection array to know which days to enable;
  let dayKeys = Object.keys(currentFields);
  let allowedDaysOfTheWeek = DAYS_OF_THE_WEEK.map((d) => d.key);
  switch (activeDays) {
    case 'workingDays':
      allowedDaysOfTheWeek = allowedDaysOfTheWeek.slice(0, 5);
      break;
    case 'weekends':
      allowedDaysOfTheWeek = allowedDaysOfTheWeek.slice(5, 7);
      break;
    default:
  }

  dayKeys = dayKeys.filter((dayKey) =>
    allowedDaysOfTheWeek.includes(
      days.filter((d) => d.key === dayKey)[0].dayOfTheWeek.key
    )
  );

  const oppositeDayKeys = Object.keys(currentFields).filter(
    (d) => !dayKeys.includes(d)
  );

  switch (activeHours) {
    case 'selectedRange':
      if (
        !activeHoursFrom ||
        !activeHoursTo ||
        // eslint-disable-next-line no-restricted-globals
        isNaN(activeHoursFrom) ||
        // eslint-disable-next-line no-restricted-globals
        isNaN(activeHoursTo)
      ) {
        break;
      }
      dayKeys.forEach((day) => {
        currentFields[day] = currentFields[day].map(
          (_, index) =>
            index >= activeHoursFrom.getHours() &&
            index <= activeHoursTo.getHours()
        );
      });
      oppositeDayKeys.forEach((day) => {
        currentFields[day] = currentFields[day].map((_) => false);
      });
      break;
    case 'unrestricted':
      dayKeys.forEach((day) => {
        currentFields[day] = currentFields[day].map(() => true);
      });
      oppositeDayKeys.forEach((day) => {
        currentFields[day] = currentFields[day].map(() => false);
      });
      break;
    case 'custom':
      oppositeDayKeys.forEach((day) => {
        currentFields[day] = currentFields[day].map(() => false);
      });
      break;
    default:
  }

  return currentFields;
}

export function getMatrixShape(days) {
  const fields = {};
  days.forEach((day) => {
    fields[day.key] = getHoursArray();
  });
  return fields;
}

function getCurrentMatrix(days, getValuesHook) {
  const currentFields = getMatrixShape(days);
  Object.keys(currentFields).forEach((day) => {
    currentFields[day] = currentFields[day].map((_, hour) =>
      getValuesHook(getDayHourName(day, hour))
    );
  });
  return currentFields;
}

export default function HourMatrix(props) {
  const {
    study,
    days,
    displayDaysOfTheWeek,
    displayWeekNumbers,
    displayDayNumbers,
  } = props;
  const [isPreviewDisplayed, setIsPreviewDisplayed] = React.useState(false);

  const { watch, setValue, getValues } = useFormContext();

  const watchedFields = watch(
    ['activeDays', 'activeHours', 'activeHoursFrom', 'activeHoursTo'],
    {
      activeDays: DEFAULT_ACTIVE_DAYS_OPTION_VALUE,
      activeHours: DEFAULT_ACTIVE_HOURS_OPTION_VALUE,
      activeHoursFrom: DEFAULT_DATE_FROM,
      activeHoursTo: DEFAULT_DATE_TO,
    }
  );

  React.useEffect(() => {
    const currentFields = getCurrentMatrix(days, getValues);
    const newFields = recalculateMatrix(...watchedFields, currentFields, days);

    Object.keys(newFields).forEach((day) => {
      newFields[day].forEach((value, hour) => {
        setValue(getDayHourName(day, hour), value);
      });
    });
  }, [days, getValues, setValue, watchedFields]);

  function selectByDayOfTheWeek(dayOfTheWeek) {
    // If all enabled - disable, otherwise - enable.
    const currentFields = getCurrentMatrix(days, getValues);
    const daysToChange = Object.keys(currentFields).filter(
      (dayKey) =>
        days.filter((d) => d.key === dayKey)[0].dayOfTheWeek.key ===
        dayOfTheWeek
    );
    const hours = getHoursArray();
    const fieldsToChange = daysToChange
      .map((dayKey) =>
        [...hours].map((_, hour) => getDayHourName(dayKey, hour))
      )
      .flat();
    const newValue = getValues(fieldsToChange).every(
      (value) => value === false
    );
    fieldsToChange.forEach((fieldKey) => {
      setValue(fieldKey, newValue);
    });
  }

  function selectByDay(dayKey) {
    const hours = getHoursArray();
    // If all enabled - disable, otherwise - enable.
    const currentValues = getValues(
      hours.map((_, hour) => getDayHourName(dayKey, hour))
    );
    const newValue = currentValues.every((value) => value === false);
    hours.forEach((value, hour) => {
      setValue(getDayHourName(dayKey, hour), newValue);
    });
  }

  function selectByWeekNumber(weekNumber) {
    // If all enabled - disable, otherwise - enable.
    const currentFields = getCurrentMatrix(days, getValues);
    const daysToChange = Object.keys(currentFields).filter(
      (dayKey) =>
        days.filter((d) => d.key === dayKey)[0].weekNumber === weekNumber
    );
    const hours = getHoursArray();
    const fieldsToChange = daysToChange
      .map((dayKey) =>
        [...hours].map((_, hour) => getDayHourName(dayKey, hour))
      )
      .flat();
    const newValue = getValues(fieldsToChange).every(
      (value) => value === false
    );
    fieldsToChange.forEach((fieldKey) => {
      setValue(fieldKey, newValue);
    });
  }

  function selectByHour(hour) {
    // If all enabled - disable, otherwise - enable.
    const currentValues = getValues(
      days.map((day) => getDayHourName(day.key, hour))
    );
    const newValue = currentValues.every((value) => value === false);
    days.forEach((day) => {
      setValue(getDayHourName(day.key, hour), newValue);
    });
  }

  if (!Array.isArray(days) || days.length < 1) {
    return <></>;
  }

  return (
    <div className={styles.hourMatrix}>
      <div className={styles.label}>
        <FormLabel>Availability matrix</FormLabel>
      </div>
      <table>
        <thead>
          <tr>
            {displayDaysOfTheWeek && <th> </th>}
            {displayDayNumbers && (
              <th>
                <p>Day</p>
              </th>
            )}
            {displayWeekNumbers && (
              <th>
                <p>Week</p>
              </th>
            )}
            {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
              <th key={`matrixHeader${hour}`} className={styles.hour}>
                <button
                  type="button"
                  onClick={() => {
                    setValue('activeHours', 'custom');
                    setValue('activeDays', null);
                    selectByHour(hour);
                  }}
                >
                  {hour}
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {days.map((day) => (
            <tr key={`${day.key}MatrixRow`}>
              {displayDaysOfTheWeek && (
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      setValue('activeHours', 'custom');
                      setValue('activeDays', null);
                      selectByDayOfTheWeek(day.dayOfTheWeek.key);
                    }}
                  >
                    {day.dayOfTheWeek.label}
                  </button>
                </th>
              )}
              {displayDayNumbers && (
                <th className={styles.centered}>
                  <button
                    type="button"
                    onClick={() => {
                      setValue('activeHours', 'custom');
                      setValue('activeDays', null);
                      selectByDay(day.key);
                    }}
                  >
                    {day.dayNumber + 1}
                  </button>
                </th>
              )}
              {displayWeekNumbers && (
                <th className={styles.centered}>
                  <button
                    type="button"
                    onClick={() => {
                      setValue('activeHours', 'custom');
                      setValue('activeDays', null);
                      selectByWeekNumber(day.weekNumber);
                    }}
                  >
                    {day.weekNumber + 1}
                  </button>
                </th>
              )}
              {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                <td key={`${day.key}Hour${hour}MatrixRow`}>
                  <HourCheckbox
                    name={getDayHourName(day.key, hour)}
                    onClick={() => {
                      setValue('activeHours', 'custom');
                      setValue('activeDays', null);
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <FormHelperText className={styles.helperText}>
        Use the above matrix to select the active hours for notification
        delivery. To toggle notifications for an entire day, click on the name
        of the day; alternatively, to toggle notification for the same hour
        across all week-days, click on the hour number.
      </FormHelperText>
      <div className={styles.showPreviewButton}>
        <Button
          variant="outlined"
          onClick={() => setIsPreviewDisplayed((current) => !current)}
        >
          {isPreviewDisplayed ? 'Hide schedule preview' : 'Preview schedule'}
        </Button>
      </div>
      {isPreviewDisplayed && (
        <SchedulePreview
          matrix={assembleMatrix(getValues(), study.duration)}
          showLabel
          displayDaysOfTheWeek={displayDaysOfTheWeek}
          displayWeekNumbers={displayWeekNumbers}
          displayDayNumbers={displayDayNumbers}
        />
      )}
    </div>
  );
}

// {[
//   'monday',
//   'tuesday',
//   'wednesday',
//   'thursday',
//   'friday',
//   'saturday',
//   'sunday',
// ].map((day) => (
//   <tr className={styles.row} key={`${day}MatrixRow`}>
//     <th>
//       <p>{day}</p>
//     </th>
//     {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
//       <td key={`${day}Hour${hour}MatrixRow`}>
//         <HourCheckbox
//           name={`${day}Hour${hour}`}
//         />
//       </td>
//     ))}
//   </tr>
// ))}
