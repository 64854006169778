import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import SchedulePreview from '../../CreateSchedule/ScheduleForm/SchedulePreview/SchedulePreview';
import styles from './StudySchedule.module.scss';
import APIClient from '../../../../../../util/APIClient';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';

export default function StudySchedule() {
  const { studyId, questionnaireId, scheduleId } = useParams();
  const navigate = useNavigate();
  const {
    data: scheduleData,
    error: scheduleError,
    isLoading: scheduleIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules/${scheduleId}`,
  });

  const {
    data: studyData,
    error: studyError,
    isLoading: studyIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });

  if (scheduleIsLoading || studyIsLoading) {
    return <Loading message="Fetching schedule..." />;
  }

  async function deleteSchedule() {
    await APIClient.delete(
      `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules/${scheduleId}`
    );
    navigate(
      `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules`
    );
  }

  // function editSchedule() {
  //   navigate(
  //     `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules/${scheduleId}/edit`
  //   );
  // }

  if (
    scheduleError ||
    studyError ||
    scheduleData === null ||
    studyData === null
  ) {
    return getErrorComponentFromHttpError(scheduleError || studyError);
  }
  const schedule = JSON.parse(scheduleData.scheduleJson);
  const displayDaysOfTheWeek = !!studyData.startingDayOfTheWeek;
  const displayDayNumbers = true;
  const displayWeekNumbers = studyData.duration % 7 === 0;

  return (
    <div className={styles.schedule}>
      <div className={styles.topRow}>
        <h1>{scheduleData.name}</h1>
        <div className={styles.buttons}>
          <Button
            color="error"
            variant="outlined"
            disabled={['IN_PROGRESS', 'FINISHED'].includes(studyData.stage)}
            onClick={() => deleteSchedule()}
          >
            Delete
          </Button>
          {/* <Button variant="outlined" onClick={() => editSchedule()}> */}
          {/*  Edit */}
          {/* </Button> */}
        </div>
      </div>

      <div className={styles.tiles}>
        <div className={styles.tile}>
          <h3>Schedule type</h3>
          <p>
            {schedule.data.formData.scheduleType === 'REPEATED_PATTERN'
              ? 'Repeated pattern'
              : 'Explicit'}
          </p>
        </div>
        {schedule.data.formData.scheduleType === 'REPEATED_PATTERN' && (
          <div className={styles.tile}>
            <h3>Repeated pattern duration</h3>
            <p>
              {schedule.data.formData.repeatedPatternDuration} day{' '}
              {schedule.data.formData.repeatedPatternDuration !== '1'
                ? 's'
                : ''}
            </p>
          </div>
        )}

        <div className={styles.tile}>
          <h3>Daily notification count</h3>
          <p>
            {schedule.data.formData.minDailyNotifications ===
            schedule.data.formData.maxDailyNotifications
              ? schedule.data.formData.minDailyNotifications
              : `Between ${schedule.data.formData.minDailyNotifications} and ${schedule.data.formData.maxDailyNotifications}`}{' '}
            per day
          </p>
        </div>
      </div>

      <div className={styles.preview}>
        <SchedulePreview
          matrix={schedule.data.matrix}
          displayDaysOfTheWeek={displayDaysOfTheWeek}
          displayDayNumbers={displayDayNumbers}
          displayWeekNumbers={displayWeekNumbers}
        />
      </div>
    </div>
  );
}
