import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormCustomValidationGroup from '../../../../Common/FormCustomValidationGroup/FormCustomValidationGroup';
import styles from './ScheduleForm.module.scss';
import FormTextField from '../../../../Common/FormTextField/FormTextField';
import FormRadioButtonGroup from '../../../../Common/FormRadioButtonGroup/FormRadioButtonGroup';
import FormTimeInput from '../../../../Common/FormTimeInput/FormTimeInput';
import HourMatrix from './HourMatrix/HourMatrix';
import {
  lowerOrEqualNumberValidator,
  predicateValidator,
} from '../../../../../../util/FormValidatorFunctions';

import {
  ACTIVE_HOURS_OPTIONS,
  ACTIVE_DAYS_OPTIONS,
  SCHEDULE_TYPE_OPTIONS,
  DEFAULT_SCHEDULE_TYPE_OPTION,
  DEFAULT_ACTIVE_DAYS_OPTION_VALUE,
  DEFAULT_ACTIVE_HOURS_OPTION_VALUE,
  DEFAULT_DATE_FROM,
  DEFAULT_DATE_TO,
  DAYS_OF_THE_WEEK,
} from './DefaultFormValues';
import FormSelect from '../../../../Common/FormSelect/FormSelect';

export default function ScheduleForm(props) {
  const { study } = props;
  const defaultRepeatedPatternDuration = Math.min(study.duration, 7);
  const { getValues, setValue } = useFormContext({
    defaultValues: {
      scheduleType: DEFAULT_SCHEDULE_TYPE_OPTION.value,
      repeatedPatternDuration: defaultRepeatedPatternDuration,
      activeDays: DEFAULT_ACTIVE_DAYS_OPTION_VALUE,
      activeHours: DEFAULT_ACTIVE_HOURS_OPTION_VALUE,
      activeHoursFrom: DEFAULT_DATE_FROM,
      activeHoursTo: DEFAULT_DATE_TO,
    },
  });

  const dailyFrequencyRangeValidator = lowerOrEqualNumberValidator(
    getValues,
    'minDailyNotifications',
    'maxDailyNotifications'
  );

  const activeHoursTimeRangeValidator = predicateValidator(
    getValues,
    'activeHoursFrom',
    'activeHoursTo',
    (a, b) => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(a) || isNaN(b)) {
        return false;
      }

      return a.getHours() <= b.getHours();
    }
  );

  const activeHours = useWatch({
    name: 'activeHours',
    defaultValue: DEFAULT_ACTIVE_HOURS_OPTION_VALUE,
  });

  const activeDays = useWatch({
    name: 'activeDays',
    defaultValue: DEFAULT_ACTIVE_DAYS_OPTION_VALUE,
  });

  const scheduleType = useWatch({
    name: 'scheduleType',
    defaultValue: DEFAULT_SCHEDULE_TYPE_OPTION.value,
  });

  const repeatedPatternDuration = useWatch({
    name: 'repeatedPatternDuration',
    defaultValue: defaultRepeatedPatternDuration,
  });

  React.useEffect(() => {
    if (activeHours !== 'custom') {
      if (!activeDays) {
        setValue('activeDays', DEFAULT_ACTIVE_DAYS_OPTION_VALUE);
      }
    } else {
      setValue('activeDays', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHours, activeHours, setValue]);

  React.useEffect(() => {
    if (activeDays) {
      if (!activeHours) {
        setValue('activeHours', DEFAULT_ACTIVE_HOURS_OPTION_VALUE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDays, activeHours, setValue]);

  function isPatternDurationValid(duration) {
    return (
      !Number.isNaN(duration) && duration <= study.duration && duration >= 1
    );
  }

  function getMatrixDays(type) {
    // If schedule is set to pattern
    let dayOffset = 0;
    let length = study.duration;
    if (
      study.startingDayOfTheWeek &&
      DAYS_OF_THE_WEEK.map((d) => d.key).includes(study.startingDayOfTheWeek)
    ) {
      dayOffset = DAYS_OF_THE_WEEK.map((d) => d.key).indexOf(
        study.startingDayOfTheWeek
      );
    }
    if (type === SCHEDULE_TYPE_OPTIONS[0].value) {
      if (!isPatternDurationValid(repeatedPatternDuration)) {
        return [];
      }
      length = repeatedPatternDuration;
    }

    return Array.from({ length }, (_, index) => {
      const dayOfTheWeek = DAYS_OF_THE_WEEK[(index + dayOffset) % 7];
      const weekNumber = dayOfTheWeek ? Math.floor(index / 7) : null;
      const label = `Day ${index}${
        study.startingDayOfTheWeek
          ? ` (${DAYS_OF_THE_WEEK[index % 7].label})`
          : ''
      }`;
      return {
        key: `day${index}`,
        dayNumber: index,
        weekNumber,
        dayOfTheWeek,
        label,
      };
    });
  }

  return (
    <>
      <FormCustomValidationGroup
        className={styles.dailyFrequencyRange}
        name="dailyFrequencyRangeValidator"
        label="Daily frequency range"
        fieldsToWatch={['minDailyNotifications', 'maxDailyNotifications']}
        customValidatorError="Number of minimum daily notifications cannot be greater than the number of maximum daily notifications."
        customValidator={() => dailyFrequencyRangeValidator()}
        defaultHelperText="Lower and upper bounds of how many notifications should be sent every day."
      >
        <FormTextField
          name="name"
          label="Name"
          autocomplete="off"
          rules={{
            required: {
              value: true,
              message: 'Schedule name is required.',
            },
            minLength: {
              value: 1,
              message: 'Schedule name needs to be at least 3 characters long.',
            },
            max: {
              value: 50,
              message: 'Schedule name cannot be longer than 50 characters.',
            },
          }}
        />
        <div className={styles.horizontalInputs}>
          <FormTextField
            name="minDailyNotifications"
            label="At least"
            autocomplete="off"
            type="number"
            rules={{
              required: {
                value: true,
                message:
                  'Lower bound for a number of daily notifications is required.',
              },
              min: {
                value: 0,
                message:
                  'Lower bound for a number of daily notifications cannot be negative.',
              },
              max: {
                value: 24,
                message:
                  'Lower bound for a number of daily notifications cannot be greater than 24.',
              },
              validate: {
                dailyFrequencyRangeValidator,
              },
            }}
          />
          <FormTextField
            name="maxDailyNotifications"
            label="At most"
            autocomplete="off"
            type="number"
            rules={{
              required: {
                value: true,
                message:
                  'Upper bound for a number of daily notifications is required.',
              },
              min: {
                value: 0,
                message:
                  'Upper bound for a number of daily notifications cannot be negative.',
              },
              max: {
                value: 24,
                message:
                  'Upper bound for a number of daily notifications cannot be greater than 24.',
              },
              validate: {
                dailyFrequencyRangeValidator,
              },
            }}
          />
        </div>
      </FormCustomValidationGroup>
      <FormCustomValidationGroup
        className={styles.scheduleType}
        name="scheduleTypeValidator"
        label="Schedule type"
        fieldsToWatch={[]}
        customValidatorError=""
        customValidator={() => {}}
      >
        <div className={styles.horizontalInputs}>
          <FormSelect
            name="scheduleType"
            label="Type"
            defaultOption={DEFAULT_SCHEDULE_TYPE_OPTION}
            defaultHelperText={
              "Mode of defining the schedule across the entire study's duration."
            }
            options={SCHEDULE_TYPE_OPTIONS}
            rules={{
              required: {
                value: true,
                message: 'Schedule type needs to be specified.',
              },
            }}
          />
          {scheduleType === 'REPEATED_PATTERN' && (
            <FormTextField
              name="repeatedPatternDuration"
              label="Pattern duration (days)"
              defaultHelperText="Number of days to repeat the pattern over."
              defaultValue={defaultRepeatedPatternDuration}
              autocomplete="off"
              type="number"
              rules={{
                required: {
                  value: true,
                  message:
                    'The number of days for pattern repetition is required.',
                },
                min: {
                  value: 0,
                  message: 'Pattern has to be at least 1 day long.',
                },
                max: {
                  value: study.duration,
                  message:
                    'Pattern cannot be longer than the study duration itself.',
                },
                validate: {
                  dailyFrequencyRangeValidator,
                },
              }}
            />
          )}
        </div>
      </FormCustomValidationGroup>
      <div className={styles.horizontalInputs}>
        <FormRadioButtonGroup
          name="activeDays"
          label="Active days"
          defaultValue={DEFAULT_ACTIVE_DAYS_OPTION_VALUE}
          values={ACTIVE_DAYS_OPTIONS.map((v) => ({
            ...v,
            disabled: !study.startingDayOfTheWeek && v.dependsOnTheDayOfTheWeek,
          }))}
        />
        <FormRadioButtonGroup
          name="activeHours"
          label="Active hours"
          defaultValue={DEFAULT_ACTIVE_HOURS_OPTION_VALUE}
          values={ACTIVE_HOURS_OPTIONS}
        />
        <FormCustomValidationGroup
          className={styles.selectedRangeHours}
          name="activeHoursRange"
          fieldsToWatch={['activeHoursFrom', 'activeHoursTo']}
          customValidatorError="Invalid time supplied. The valid time ranges between 0 (from midnight to 1am) and 23 (from 11pm to midnight); the start time also cannot happen after the end time."
          customValidator={() => {
            if (activeHours === 'selectedRange') {
              return activeHoursTimeRangeValidator();
            }
            return true;
          }}
        >
          <div className={styles.horizontalInputs}>
            <FormTimeInput
              name="activeHoursFrom"
              label="From"
              defaultValue={DEFAULT_DATE_FROM}
              className={styles.activeHoursTextInput}
              disabled={activeHours !== 'selectedRange'}
            />
            <FormTimeInput
              name="activeHoursTo"
              label="To"
              defaultValue={DEFAULT_DATE_TO}
              className={styles.activeHoursTextInput}
              disabled={activeHours !== 'selectedRange'}
            />
          </div>
        </FormCustomValidationGroup>
      </div>
      <HourMatrix
        study={study}
        days={getMatrixDays(scheduleType)}
        displayDaysOfTheWeek={
          study.startingDayOfTheWeek &&
          (repeatedPatternDuration % 7 === 0 ||
            scheduleType === SCHEDULE_TYPE_OPTIONS[1].value)
        }
        displayDayNumbers
        displayWeekNumbers={
          scheduleType === SCHEDULE_TYPE_OPTIONS[1].value && study.duration > 7
        }
      />
    </>
  );
}
