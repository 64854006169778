import React from 'react';
import uuid from 'react-uuid';
import FormLabel from '@mui/material/FormLabel';
import styles from './SchedulePreview.module.scss';
import { DAYS_OF_THE_WEEK } from '../DefaultFormValues';

export default function SchedulePreview(props) {
  const {
    matrix,
    showLabel,
    displayDaysOfTheWeek,
    displayDayNumbers,
    displayWeekNumbers,
  } = props;

  return (
    <div className={styles.schedulePreview}>
      {showLabel && (
        <div className={styles.label}>
          <FormLabel>Schedule preview</FormLabel>
        </div>
      )}
      <table>
        <thead>
          <tr>
            {displayDaysOfTheWeek && <th> </th>}
            {displayDayNumbers && (
              <th>
                <p>Day</p>
              </th>
            )}
            {displayWeekNumbers && (
              <th>
                <p>Week</p>
              </th>
            )}
            {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
              <th key={`matrixHeader${hour}`} className={styles.hour}>
                {hour}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {matrix.map((day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={uuid()}>
              {displayDaysOfTheWeek && (
                <th>{DAYS_OF_THE_WEEK[index % 7].label}</th>
              )}
              {displayDayNumbers && (
                <th className={styles.centered}>{index + 1}</th>
              )}
              {displayWeekNumbers && (
                <th className={styles.centered}>
                  {Math.floor((index + 1) / 7)}
                </th>
              )}
              {day.map((hour) => (
                <td
                  key={uuid()}
                  className={`${styles.hourBox} ${
                    hour ? styles.available : styles.unavailable
                  }`}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
