import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Form from '../../../../Common/Form/Form';
import APIClient from '../../../../../../util/APIClient';
import styles from './InviteStudyParticipant.module.scss';
import FormTextField from '../../../../Common/FormTextField/FormTextField';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';

export default function InviteStudyParticipant() {
  const { studyId } = useParams();
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = React.useState(null);

  const { data: study, isLoading } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });

  React.useEffect(() => {
    if (study) {
      if (study.stage && study.stage === 'FINISHED') {
        navigate(`/researcher/studies/${studyId}`);
      }
    }
  }, [study, navigate, studyId]);

  if (isLoading) {
    return <Loading />;
  }

  async function onSubmit(data) {
    const userData = {
      userEmail: data.email,
      firstName: data.firstName || '',
      lastName: data.lastName || '',
    };
    try {
      await APIClient.post(
        `/researcher/studies/${studyId}/participants`,
        userData
      );
      setFormAlert({
        severity: 'success',
        title: 'Participant invited successfully!',
        message:
          'Participant has been invited successfully. An invitation e-mail will be sent to their e-mail address.',
      });
    } catch (e) {
      setFormAlert({
        severity: 'error',
        title: 'Could not invite a participant!',
        message:
          'Something went wrong during the participant invitation. Please contact the administrator for further support.',
      });
    }
  }

  return (
    <div className={styles.inviteParticipant}>
      <p className={styles.description}>
        Use this form to invite a new participant to the study. If the account
        with this e-mail already exists, they will be send an invitation to join
        the study; otherwise, they will be sent an invitation to register on the
        platform first, and invited to join the study afterwards.
      </p>
      <p className={styles.description}>
        If the study is in a draft stage, the invitation will not be sent until
        after it advances to the recruitment stage.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <div className={styles.horizontalInputs}>
          <FormTextField
            name="firstName"
            label="First name"
            autocomplete="given-name"
            rules={{
              required: { value: true, message: 'First name is required.' },
            }}
          />
          <FormTextField
            name="lastName"
            label="Last name"
            autocomplete="family-name"
            rules={{
              required: { value: true, message: 'Last name is required.' },
            }}
          />
        </div>
        <FormTextField
          name="email"
          label="E-mail address"
          autocomplete="email"
          rules={{
            required: { value: true, message: 'E-mail address is required.' },
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'E-mail address must be a valid e-mail.',
            },
          }}
        />
        <div className={styles.formControls}>
          <Button variant="contained" type="submit" fullWidth>
            Send invitation
          </Button>
        </div>
        {formAlert && (
          <div className={styles.alert}>
            <Alert severity={formAlert.severity}>
              {formAlert.title && <AlertTitle>{formAlert.title}</AlertTitle>}
              {formAlert.message}
            </Alert>
          </div>
        )}
      </Form>
    </div>
  );
}
