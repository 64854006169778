// Easter egg
const DEFAULT_DATE_DAY = '1997-11-18';
const DATE_FROM = new Date(DEFAULT_DATE_DAY);
const DATE_TO = new Date(DEFAULT_DATE_DAY);
DATE_FROM.setHours(9);
DATE_TO.setHours(17);

export const ACTIVE_DAYS_OPTIONS = [
  { name: 'everyday', label: 'Everyday', dependsOnTheDayOfTheWeek: false },
  {
    name: 'workingDays',
    label: 'Working days only',
    dependsOnTheDayOfTheWeek: true,
  },
  { name: 'weekends', label: 'Weekends only', dependsOnTheDayOfTheWeek: true },
];

export const ACTIVE_HOURS_OPTIONS = [
  { name: 'selectedRange', label: 'Selected range' },
  { name: 'unrestricted', label: 'Unrestricted' },
  { name: 'custom', label: 'Custom selection' },
];

export const SCHEDULE_TYPE_OPTIONS = [
  {
    key: 'repeatedPattern',
    label: 'Repeated pattern',
    value: 'REPEATED_PATTERN',
  },
  {
    key: 'perDayDefinition',
    label: 'Custom pattern every day',
    value: 'INDEPENDENT',
  },
];

export const DAYS_OF_THE_WEEK = [
  { key: 'monday', label: 'Monday' },
  { key: 'tuesday', label: 'Tuesday' },
  { key: 'wednesday', label: 'Wednesday' },
  { key: 'thursday', label: 'Thursday' },
  { key: 'friday', label: 'Friday' },
  { key: 'saturday', label: 'Saturday' },
  { key: 'sunday', label: 'Sunday' },
];

export const DEFAULT_ACTIVE_DAYS_OPTION_VALUE = ACTIVE_DAYS_OPTIONS[0].name;
export const DEFAULT_ACTIVE_HOURS_OPTION_VALUE = ACTIVE_HOURS_OPTIONS[0].name;
export const DEFAULT_SCHEDULE_TYPE_OPTION = SCHEDULE_TYPE_OPTIONS[0];

export const DEFAULT_DATE_FROM = DATE_FROM;
export const DEFAULT_DATE_TO = DATE_TO;
