import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function StudyQuestionnaires() {
  const navigate = useNavigate();
  const { studyId } = useParams();
  const {
    data: studyData,
    error: studyError,
    isLoading: studyIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });
  const {
    data: questionnairesData,
    error: questionnairesError,
    isLoading: questionnairesIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}/questionnaires`,
    params: {
      includeScheduleCount: true,
    },
  });

  function createNewQuestionnaire() {
    navigate('create');
  }

  if (studyIsLoading || questionnairesIsLoading) {
    return <Loading message="Fetching questionnaires..." />;
  }

  if (
    studyError ||
    questionnairesError ||
    studyData === null ||
    questionnairesData === null
  ) {
    return getErrorComponentFromHttpError(studyError || questionnairesError);
  }

  return (
    <Table
      rows={questionnairesData}
      topButtons={[
        {
          label: 'Create questionnaire',
          handler:
            studyData.stage === 'FINISHED'
              ? null
              : () => {
                  createNewQuestionnaire();
                },
        },
      ]}
      title="Questionnaires"
      rowLinks={{
        name: {
          label: 'View questionnaire',
          to: (id) => `/researcher/studies/${studyId}/questionnaires/${id}`,
        },
        scheduleCount: {
          label: 'View schedules',
          to: (id) =>
            `/researcher/studies/${studyId}/questionnaires/${id}/schedules`,
        },
      }}
      columns={[
        {
          id: 'name',
          label: 'Questionnaire name',
          numeric: false,
        },
        {
          id: 'scheduleCount',
          label: 'Schedules',
          numeric: true,
        },
      ]}
    />
  );
}
