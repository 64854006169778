import React from 'react';
import uuid from 'react-uuid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormContext } from 'react-hook-form';
import styles from './QuestionnairePrompts.module.scss';
import Prompt from './Prompt/Prompt';
import FormCustomValidationGroup from '../../../../../Common/FormCustomValidationGroup/FormCustomValidationGroup';
import { fieldCountValidator } from '../../../../../../../util/FormValidatorFunctions';

export default function QuestionnairePrompts() {
  const { unregister } = useFormContext();
  const [notificationPrompts, setNotificationPrompts] = React.useState([
    {
      id: uuid(),
    },
  ]);

  function addPrompt() {
    setNotificationPrompts((current) => [
      ...current,
      {
        id: uuid(),
      },
    ]);
  }

  function removePrompt(id) {
    unregister(`notificationPrompts.${id}`);
    setNotificationPrompts((current) =>
      current.filter((prompt) => prompt.id !== id)
    );
  }

  return (
    <div className={styles.promptsForm}>
      <FormLabel>Notification prompts</FormLabel>
      <FormHelperText>
        Notification prompts is a brief text descriptions displayed in the
        notification when the participant is prompted to respond to a
        questionnaire. The prompt will be selected at random each time.
      </FormHelperText>
      <FormCustomValidationGroup
        className={styles.promptsValidator}
        name="promptsValidator"
        customValidatorError="At least one notification prompt is required for the questionnaire."
        customValidator={() =>
          fieldCountValidator(
            notificationPrompts.map(
              (prompt) => `notificationPrompts.${prompt.id}`
            ),
            1
          )
        }
        fieldsToWatch={[]}
      >
        {notificationPrompts.length > 0 && (
          <div className={styles.prompts}>
            {notificationPrompts.map((prompt) => (
              <Prompt
                key={prompt.id}
                id={prompt.id}
                removePrompt={() => removePrompt(prompt.id)}
              />
            ))}
          </div>
        )}
      </FormCustomValidationGroup>
      <div className={styles.addPrompt}>
        <Button
          type="button"
          variant="outlined"
          onClick={() => addPrompt()}
          startIcon={<AddIcon />}
        >
          Add new prompt
        </Button>
      </div>
    </div>
  );
}
