import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './Questionnaire.module.scss';
import useApiData from '../../../../../hooks/useApiData';
import QuestionnairePreview from './QuestionnairePreview/QuestionnairePreview';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function CreateSchedule() {
  const { studyId, questionnaireId } = useParams();
  const {
    data: studyData,
    error: studyError,
    isLoading: studyIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });
  const {
    data: questionnaireData,
    error: questionnaireError,
    isLoading: questionnaireIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}/questionnaires/${questionnaireId}`,
    params: {
      includeQuestions: true,
      includeQuestionAnswers: true,
      includeNotificationPrompts: true,
    },
  });

  if (studyIsLoading || questionnaireIsLoading) {
    return <Loading message="Fetching questionnaire..." />;
  }

  if (
    studyError ||
    questionnaireError ||
    studyData === null ||
    questionnaireData === null
  ) {
    return getErrorComponentFromHttpError(studyError || questionnaireError);
  }

  return (
    <div className={styles.questionnaire}>
      <QuestionnairePreview
        questionnaire={questionnaireData}
        study={studyData}
      />
    </div>
  );
}
