import React from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import styles from './Prompt.module.scss';
import FormTextField from '../../../../../../Common/FormTextField/FormTextField';

export default function Prompt(props) {
  const { id, removePrompt } = props;

  return (
    <div className={styles.prompt}>
      <div className={styles.header}>
        <p>Notification Prompt</p>
        <div className={styles.buttons}>
          <Tooltip title="Delete prompt">
            <IconButton onClick={removePrompt}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={styles.fields}>
        <FormTextField
          name={`notificationPrompts.${id}.title`}
          label="Notification title"
          multiline
          rules={{
            required: {
              value: true,
              message: 'Notification title is required.',
            },
          }}
        />
        <FormTextField
          name={`notificationPrompts.${id}.body`}
          label="Notification text"
          multiline
          rules={{
            required: {
              value: true,
              message: 'Notification body is required.',
            },
          }}
        />
      </div>
    </div>
  );
}
