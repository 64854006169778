import React from 'react';
import { useFormContext } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import FormTextField from '../../../../Common/FormTextField/FormTextField';
import styles from './StudyForm.module.scss';
import FormSelect from '../../../../Common/FormSelect/FormSelect';

export default function StudyForm() {
  const { setValue } = useFormContext();
  const daysOfTheWeekOptions = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ].map((day) => ({
    key: day.toLowerCase(),
    label: day,
    value: day.toLowerCase(),
  }));

  return (
    <>
      <FormTextField
        name="name"
        label="Study name"
        autocomplete="off"
        rules={{
          minLength: {
            value: 5,
            message: 'Study name needs to be at least 5 characters long.',
          },
          maxLength: {
            value: 80,
            message: 'Study name cannot be longer than 80 characters.',
          },
          required: { value: true, message: 'Study name is required.' },
        }}
      />
      <FormTextField
        name="description"
        label="Short description"
        multiline
        autocomplete="off"
        rules={{
          minLength: {
            value: 30,
            message:
              'Study description needs to be at least 30 characters long.',
          },
          maxLength: {
            value: 250,
            message: 'Study description cannot be longer than 250 characters.',
          },
          required: {
            value: true,
            message: 'Study description is required.',
          },
        }}
      />
      <FormTextField
        name="duration"
        type="number"
        label="Study duration"
        defaultHelperText="Duration of the study for each individual participant in days."
        autocomplete="off"
        rules={{
          min: {
            value: 1,
            message: 'Study needs to be at least 1 day long.',
          },
          max: {
            value: 1000,
            message: 'Study cannot be longer than 1000 days long.',
          },
          required: {
            value: true,
            message: 'Study duration is required.',
          },
        }}
      />
      <div className={styles.clearableSelect}>
        <FormSelect
          name="startingDayOfTheWeek"
          label="Starting day of the week"
          options={daysOfTheWeekOptions}
          defaultHelperText="Optional day of the week on which the study starts for each participant."
        />
        <Tooltip title="Clear selection">
          <IconButton onClick={() => setValue('startingDayOfTheWeek', '')}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}
