import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function consolidateSubmissions(data) {
  return data.questionnaireSubmissions.map((submission) => {
    const matchingQuestionnaires = data.questionnaires.filter(
      (questionnaire) => questionnaire.id === submission.questionnaireId
    );
    let answers = submission.participantAnswers;

    // Attempt to match questionnaire to the submission to resolve content for each answer
    if (matchingQuestionnaires.length === 1) {
      const questionnaire = matchingQuestionnaires[0];
      answers = submission.participantAnswers.map((answer) => {
        const matchingQuestions = questionnaire.questions.filter(
          (question) => question.id === answer.questionId
        );
        let answerContent = answer.content;

        // Only process answers which define questionAnswerId and have a matching question in questionnaire
        if (
          answer.questionAnswerId !== null &&
          matchingQuestions.length === 1
        ) {
          const matchingQuestionAnswers =
            matchingQuestions[0].questionAnswers.filter(
              (questionAnswer) => questionAnswer.id === answer.questionAnswerId
            );

          // Only resolve the answer content if there is a matching questionAnswer
          if (matchingQuestionAnswers.length === 1) {
            answerContent = matchingQuestionAnswers[0].content;
          }
        }

        return {
          ...answer,
          content: answerContent,
        };
      });
    }

    // Strip unnecessary answer properties.
    answers = answers.map((answer) => ({
      questionId: answer.questionId,
      questionAnswerId: answer.questionAnswerId,
      content: answer.content,
    }));

    return {
      id: submission.id,
      submittedAt: submission.createdAt,
      questionnaireId: submission.questionnaireId,
      participantId: submission.participantId,
      answers,
      notificationSentAt:
        submission.notification && submission.notification.sentAt
          ? submission.notification.sentAt
          : null,
    };
  });
}

function consolidateNotifications(data) {
  const notifications = data.notifications;
  const questionnaireSubmissions = data.questionnaireSubmissions;

  return notifications.map((n) => {
    const matchingQuestionnaireSubmissions = questionnaireSubmissions.filter(
      (qs) => qs.notificationId === n.id
    );
    let questionnaireSubmittedAt = null;
    if (matchingQuestionnaireSubmissions.length === 1) {
      questionnaireSubmittedAt = matchingQuestionnaireSubmissions[0].createdAt;
    }
    return { ...n, questionnaireSubmittedAt };
  });
}

function consolidateSchedules(data) {
  const questionnaires = data.questionnaires;

  return questionnaires
    .map((q) =>
      q.schedules.map((s) => {
        const schedule = JSON.parse(s.scheduleJson);
        const {
          minDailyNotifications,
          maxDailyNotifications,
          scheduleType,
          repeatedPatternDuration,
        } = schedule.data.formData;
        return {
          id: s.id,
          name: s.name,
          createdAt: s.createdAt,
          minDailyNotifications: minDailyNotifications || null,
          maxDailyNotifications: maxDailyNotifications || null,
          scheduleType,
          repeatedPatternDuration: repeatedPatternDuration || null,
          matrix: schedule.data.matrix
            .map((day) => day.map((hour) => (hour ? 1 : 0)).join(','))
            .join(';'),
        };
      })
    )
    .flat();
}

function computeAdherence(data) {
  const questionnaireSubmissions = data.questionnaireSubmissions;
  const notifications = data.notifications;
  const participants = data.participants;
  const totalAdherence = [];

  participants
    .filter((p) => p.invitationAcceptedAt !== null)
    .forEach((p) => {
      const participantSubmissions = questionnaireSubmissions.filter(
        (qs) => qs.participantId === p.id
      );
      const participantNotifications = notifications.filter(
        (n) => n.participantId === p.id
      );

      for (
        let i = 1, date = new Date(p.startDate);
        date <= new Date(p.endDate);
        date = new Date(date), date.setDate(date.getDate() + 1), i += 1
      ) {
        const simplifiedDate = date.toISOString().substring(0, 10);
        const dateMatches = (x) =>
          x.toISOString().substring(0, 10) === simplifiedDate;
        const submissions = participantSubmissions.filter((qs) =>
          dateMatches(new Date(qs.createdAt))
        );
        const eventDrivenSubmissions = submissions.filter(
          (qs) => qs.notificationId === null
        );
        const notificationsSent = participantNotifications.filter((n) =>
          dateMatches(new Date(n.scheduledFor))
        );
        const notificationsRespondedTo = submissions.filter(
          (qs) => qs.notificationId !== null
        );

        const adherence =
          notificationsSent.length === 0
            ? null
            : (
                notificationsRespondedTo.length / notificationsSent.length
              ).toFixed(3);

        totalAdherence.push({
          participantId: p.id,
          dayOfStudy: i,
          date,
          adherence,
          totalSubmissions: submissions.length,
          notificationsSent: notificationsSent.length,
          notificationsRespondedTo: notificationsRespondedTo.length,
          eventDrivenSubmissions: eventDrivenSubmissions.length,
        });
      }
    });

  return totalAdherence;
}

function tabulariseNotifications(notifications) {
  const header =
    'id,participantId,questionnaireId,scheduleId,createdAt,scheduledFor,sentAt,questionnaireSubmittedAt';
  const text = notifications.map(
    (n) =>
      `${n.id},${n.participantId},${n.questionnaireId},${n.scheduleId},${n.createdAt},${n.scheduledFor},${n.sentAt},${n.questionnaireSubmittedAt}`
  );

  return [header, ...text].join('\n');
}
function tabulariseSchedules(schedules) {
  const header =
    'id,name,createdAt,minDailyNotifications,maxDailyNotifications,scheduleType,repeatedPatternDuration,matrix';
  const text = schedules.map(
    (s) =>
      `${s.id},"${s.name.replace('"', "''")}",${s.createdAt},${
        s.minDailyNotifications
      },${s.maxDailyNotifications},${s.scheduleType},${
        s.repeatedPatternDuration
      },"${s.matrix}"`
  );

  return [header, ...text].join('\n');
}

function tabulariseAdherence(adherence) {
  const header =
    'participantId,dayOfStudy,date,adherence,totalSubmissions,eventDrivenSubmissions,notificationsSent,notificationsRespondedTo';
  const text = adherence.map(
    (a) =>
      `${a.participantId},${a.dayOfStudy},${a.date},${a.adherence},${a.totalSubmissions},${a.eventDrivenSubmissions},${a.notificationsSent},${a.notificationsRespondedTo}`
  );

  return [header, ...text].join('\n');
}

function tabulariseSubmissions(submissions) {
  const composedAnswers = submissions.map((submission) =>
    submission.answers.map((answer) => ({
      submissionId: submission.id,
      notificationSentAt: submission.notificationSentAt,
      submittedAt: submission.submittedAt,
      participantId: submission.participantId,
      questionnaireId: submission.questionnaireId,
      questionId: answer.questionId,
      answerId: answer.questionAnswerId,
      answer: answer.content,
    }))
  );
  const table = composedAnswers.flat();

  const header =
    'submissionId,notificationSentAt,submittedAt,participantId,questionnaireId,questionId,answerId,answer';
  const text = table.map((a) => {
    const cleanedAnswer = a.answer.replace('"', "''");
    return `${a.submissionId},${a.notificationSentAt},${a.submittedAt},${a.participantId},${a.questionnaireId},${a.questionId},${a.answerId},"${cleanedAnswer}"`;
  });

  return [header, ...text].join('\n');
}

function tabulariseParticipants(participants) {
  const composedParticipants = participants.map((participant) => ({
    participantId: participant.id,
    givenName:
      (participant.user && (participant.user.givenName || null)) || null,
    familyName:
      (participant.user && (participant.user.familyName || null)) || null,
    email: (participant.user && (participant.user.email || null)) || null,
    startDate: participant.startDate,
    endDate: participant.endDate,
    invitationSentAt: participant.invitationSentAt,
    invitationAcceptedAt: participant.invitationAcceptedAt,
    invitationRejectedAt: participant.invitationRejectedAt,
  }));

  const detailsPresent = composedParticipants.some(
    (p) => p.givenName || p.familyName || p.email
  );

  const header = `participantId,${
    detailsPresent ? 'givenName,familyName,email,' : ''
  }startDate,endDate,invitationSentAt,invitationAcceptedAt,invitationRejectedAt`;

  const text = composedParticipants.map((p) => {
    const cleanedGivenName = detailsPresent
      ? p.givenName.replace('"', "''")
      : null;
    const cleanedFamilyName = detailsPresent
      ? p.familyName.replace('"', "''")
      : null;
    const cleanedEmail = detailsPresent ? p.email.replace('"', "''") : null;
    return `${p.participantId},${
      detailsPresent
        ? `"${cleanedGivenName}","${cleanedFamilyName}","${cleanedEmail}",`
        : ''
    }${p.startDate},${p.endDate},${p.invitationSentAt},${
      p.invitationAcceptedAt
    },${p.invitationRejectedAt}`;
  });

  return [header, ...text].join('\n');
}

function tabulariseQuestions(questionnaires) {
  const composedAnswers = questionnaires.map((questionnaire) => {
    const questions = questionnaire.questions.map((question) => {
      const answers = question.questionAnswers.map((questionAnswer) => ({
        answerId: questionAnswer.id,
        answerContent: questionAnswer.content,
        nextQuestionId: questionAnswer.nextQuestionId,
      }));

      if (answers.length === 0) {
        answers.push({
          answerId: null,
          answerContent: null,
          nextQuestionId: null,
        });
      }

      return answers.map((answer) => ({
        questionId: question.id,
        questionPrompt: question.prompt,
        questionType: question.type,
        questionPlaceholder: question.placeholder,
        ...answer,
        nextQuestionId:
          answer.nextQuestionId !== null
            ? answer.nextQuestionId
            : question.nextQuestionId,
      }));
    });

    return questions.flat().map((question) => ({
      questionnaireId: questionnaire.id,
      questionnaireName: questionnaire.name,
      questionIsFirst: questionnaire.rootQuestionId === question.questionId,
      ...question,
    }));
  });

  const table = composedAnswers.flat();

  const header =
    'questionnaireId,questionnaireName,questionIsFirst,questionId,questionPrompt,questionType,questionPlaceholder,answerId,answerContent,nextQuestionId';
  const text = table.map((a) => {
    const cleanedQuestionnaireName = a.questionnaireName.replace('"', "''");
    const cleanedQuestionPrompt = a.questionPrompt.replace('"', "''");
    const cleanedQuestionPlaceholder =
      typeof a.questionPlaceholder === 'string'
        ? a.questionPlaceholder.replace('"', "''")
        : a.questionPlaceholder;
    const cleanedAnswerContent =
      typeof a.answerContent === 'string'
        ? a.answerContent.replace('"', "''")
        : a.answerContent;
    return `${a.questionnaireId},"${cleanedQuestionnaireName}",${a.questionIsFirst},${a.questionId},"${cleanedQuestionPrompt}",${a.questionType},"${cleanedQuestionPlaceholder}",${a.answerId},"${cleanedAnswerContent}",${a.nextQuestionId}`;
  });

  return [header, ...text].join('\n');
}

function getFilename() {
  return `${new Date().toISOString().replace(':', '-').replace('.', '')}.zip`;
}

export async function exportToCsv(data) {
  const zip = new JSZip();
  const consolidatedSubmissions = consolidateSubmissions(data);

  const tabularisedSubmissions = tabulariseSubmissions(consolidatedSubmissions);
  zip.file('questionnaire_submissions.csv', tabularisedSubmissions);

  const tabularisedQuestions = tabulariseQuestions(data.questionnaires);
  zip.file('questionnaires.csv', tabularisedQuestions);

  const consolidatedNotifications = consolidateNotifications(data);
  const tabularisedNotifications = tabulariseNotifications(
    consolidatedNotifications
  );
  zip.file('notifications.csv', tabularisedNotifications);

  const consolidatedSchedules = consolidateSchedules(data);
  const tabularisedSchedules = tabulariseSchedules(consolidatedSchedules);
  zip.file('schedules.csv', tabularisedSchedules);

  const computedAdherence = computeAdherence(data);
  const tabularisedAdherence = tabulariseAdherence(computedAdherence);
  zip.file('adherence.csv', tabularisedAdherence);

  const tabularisedParticipants = tabulariseParticipants(data.participants);
  zip.file('participants.csv', tabularisedParticipants);

  zip.file('info.txt', `Data dump generated on ${new Date().toISOString()}.`);
  return zip.generateAsync({ type: 'blob' }).then((blob) => {
    // eslint-disable-next-line no-undef
    saveAs(blob, getFilename());
  });
}

export async function exportToJson(data) {
  const zip = new JSZip();
  const consolidatedSubmissions = consolidateSubmissions(data);

  zip.file(
    'questionnaire_submissions.json',
    JSON.stringify(consolidatedSubmissions)
  );

  zip.file('questionnaires.json', JSON.stringify(data.questionnaires));

  const consolidatedNotifications = consolidateNotifications(data);
  zip.file('notifications.json', JSON.stringify(consolidatedNotifications));

  const consolidatedSchedules = consolidateSchedules(data);
  zip.file('schedules.csv', consolidatedSchedules);

  const computedAdherence = computeAdherence(data);
  zip.file('adherence.json', JSON.stringify(computedAdherence));

  zip.file('participants.json', JSON.stringify(data.participants));

  zip.file('info.txt', `Data dump generated on ${new Date().toISOString()}.`);
  return zip.generateAsync({ type: 'blob' }).then((blob) => {
    // eslint-disable-next-line no-undef
    saveAs(blob, getFilename());
  });
}
