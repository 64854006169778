import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table, {USER_STATUS_COLUMN_ENUM} from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function StudyResearchers() {
  const navigate = useNavigate();
  const { studyId } = useParams();
  const { data, error, isLoading } = useApiData({
    path: `/administrator/studies/${studyId}/researchers`,
  });

  function inviteResearcher() {
    navigate('/administrator/users/researchers/invite');
  }

  if (isLoading) {
    return <Loading message="Fetching researchers..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const displayedData = data.map((researcher) => ({
    ...researcher.user,
    isLead: researcher.isLead,
  }));

  return (
    <Table
      rows={displayedData}
      topButtons={[
        {
          label: 'Invite researcher',
          handler: () => {
            inviteResearcher();
          },
        },
      ]}
      title="Researchers"
      columns={[
        {
          id: 'givenName',
          label: 'First name',
          numeric: false,
        },
        {
          id: 'familyName',
          label: 'Last name',
          numeric: false,
        },
        {
          id: 'email',
          label: 'E-mail address',
          numeric: false,
        },
        {
          id: 'isEmailVerified',
          label: 'Email verified',
          boolean: true,
        },
        {
          id: 'isLead',
          label: 'Lead',
          boolean: true,
        },
        {
          id: 'status',
          label: 'Account status',
          numeric: false,
          enum: USER_STATUS_COLUMN_ENUM,
        },
        {
          id: 'isEnabled',
          label: 'Enabled',
          boolean: true,
        },
      ]}
    />
  );
}
