import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Table, {
  PARTICIPANT_INVITATION_STATUS_COLUMN_ENUM,
  PARTICIPANT_PARTICIPATION_STATUS_COLUMN_ENUM,
} from '../../../../Common/Table/Table/Table';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import APIClient from '../../../../../../util/APIClient';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';

function getParticipantInvitationStatus(participant) {
  if (participant.invitationSentAt) {
    if (participant.invitationAcceptedAt) {
      return 'ACCEPTED';
    }
    if (participant.invitationRejectedAt) {
      return 'REJECTED';
    }
    return 'SENT';
  }
  return 'NOT_SENT';
}

function getParticipantParticipationStatus(participant) {
  if (participant.startDate && participant.endDate) {
    const startDate = new Date(participant.startDate).getTime();
    const endDate = new Date(participant.endDate).getTime();
    const now = new Date().getTime();

    if (startDate <= now) {
      if (endDate >= now) {
        return 'IN_PROGRESS';
      }
      return 'FINISHED';
    }
  }
  return 'NOT_STARTED';
}

export default function StudyParticipants() {
  const navigate = useNavigate();
  const { studyId } = useParams();
  const {
    data: studyData,
    error: studyError,
    isLoading: studyIsLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });
  const {
    data: participantsData,
    error: participantsError,
    isLoading: participantsIsLoading,
    reload: participantsReload,
  } = useApiData({
    path: `/researcher/studies/${studyId}/participants`,
    params: {
      includeStudyQuestionnaireQuestionnaireSubmissionCount: true,
      includeLatestSubmissionDate: true,
    },
  });

  async function removeUsersFromStudy(userIds) {
    await APIClient.delete(`/researcher/studies/${studyId}/participants`, {
      data: {
        userIds,
      },
    });
    participantsReload();
  }

  function inviteParticipant() {
    navigate('invite');
  }

  if (participantsIsLoading || studyIsLoading) {
    return <Loading message="Fetching participants..." />;
  }

  if (
    studyError ||
    participantsError ||
    studyData === null ||
    participantsData === null
  ) {
    return getErrorComponentFromHttpError(studyError || participantsError);
  }

  const displayedData = participantsData.map((participant) => ({
    ...participant,
    ...participant.user,
    invitationStatus: getParticipantInvitationStatus(participant),
    participationStatus: getParticipantParticipationStatus(participant),
    questionnaireSubmissionCount: `${participant.questionnaireNotificationSubmissionCount} / ${participant.questionnaireNotificationCount} (+${participant.questionnaireEventDrivenSubmissionCount})`,
  }));

  const tableColumns = [
    {
      id: 'givenName',
      label: 'First name',
      numeric: false,
    },
    {
      id: 'familyName',
      label: 'Last name',
      numeric: false,
    },
    {
      id: 'email',
      label: 'E-mail address',
      numeric: false,
    },
    {
      id: 'invitationStatus',
      label: 'Invitation status',
      numeric: false,
      enum: PARTICIPANT_INVITATION_STATUS_COLUMN_ENUM,
    },
  ];

  if (['RECRUITMENT', 'IN_PROGRESS', 'FINISHED'].includes(studyData.stage)) {
    tableColumns.push(
      {
        id: 'participationStatus',
        label: 'Status',
        numeric: false,
        enum: PARTICIPANT_PARTICIPATION_STATUS_COLUMN_ENUM,
      },
      {
        id: 'startDate',
        label: 'Start Date',
        date: true,
      },
      {
        id: 'endDate',
        label: 'End Date',
        date: true,
      },
      {
        id: 'questionnaireSubmissionCount',
        label: 'Submissions',
        numeric: false,
      },
      {
        id: 'questionnaireNotificationComplianceRate',
        label: 'Adherence',
        numeric: true,
      },
      {
        id: 'latestSubmissionDate',
        label: 'Latest submission',
        date: true,
        time: true,
      }
    );
  }

  return (
    <Table
      rows={displayedData}
      topButtons={[
        {
          label: 'Invite participant',
          handler:
            studyData.stage === 'FINISHED'
              ? null
              : () => {
                  inviteParticipant();
                },
        },
      ]}
      title="Participants"
      allowSelect
      selectedActions={[
        {
          handler: (userIds) => removeUsersFromStudy(userIds),
          label: 'Remove from study',
          icon: <PersonRemoveIcon />,
          clearSelectionAfterAction: true,
        },
      ]}
      columns={tableColumns}
    />
  );
}
