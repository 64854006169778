import React from 'react';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Table, {
  USER_STATUS_COLUMN_ENUM,
} from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import APIClient from '../../../../../util/APIClient';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function Researchers() {
  const navigate = useNavigate();
  const { data, error, isLoading, reload } = useApiData({
    path: '/administrator/users/groups/researchers',
    params: {
      includeStudyCount: true,
    },
  });

  async function disableUsers(userIds) {
    await APIClient.patch('/administrator/users', {
      userIds,
      operation: 'disable',
    });
    reload();
  }

  async function enableUsers(userIds) {
    await APIClient.patch('/administrator/users', {
      userIds,
      operation: 'enable',
    });
    reload();
  }

  function inviteResearcher() {
    navigate('invite');
  }

  if (isLoading) {
    return <Loading message="Fetching researchers..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <Table
      rows={data}
      rowLinks={{
        studyCount: {
          label: "View researcher's studies",
          to: (id) => `/administrator/studies?researcherId=${id}`,
        },
        leadStudyCount: {
          label: 'View studies where the researcher is a lead',
          to: (id) =>
            `/administrator/studies?researcherId=${id}&leadResearcher=true`,
        },
      }}
      topButtons={[
        {
          label: 'Invite researcher',
          handler: () => {
            inviteResearcher();
          },
        },
      ]}
      title="Researchers"
      allowSelect
      selectedActions={[
        {
          handler: (userIds) => enableUsers(userIds),
          label: 'Enable users',
          icon: <LockOpenIcon />,
        },
        {
          handler: (userIds) => disableUsers(userIds),
          label: 'Disable users',
          icon: <LockIcon />,
        },
      ]}
      columns={[
        {
          id: 'givenName',
          label: 'First name',
          numeric: false,
        },
        {
          id: 'familyName',
          label: 'Last name',
          numeric: false,
        },
        {
          id: 'email',
          label: 'E-mail address',
          numeric: false,
        },
        {
          id: 'isEmailVerified',
          label: 'Email verified',
          boolean: true,
          shrink: true,
        },
        {
          id: 'studyCount',
          label: 'Studies',
          numeric: true,
          shrink: true,
        },
        {
          id: 'leadStudyCount',
          label: 'Lead for studies',
          numeric: true,
          shrink: true,
        },
        {
          id: 'status',
          label: 'Account status',
          numeric: false,
          enum: USER_STATUS_COLUMN_ENUM,
        },
        {
          id: 'isEnabled',
          label: 'Enabled',
          boolean: true,
          shrink: true,
        },
      ]}
    />
  );
}
