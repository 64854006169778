import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import styles from './CreateSchedule.module.scss';
import APIClient from '../../../../../util/APIClient';
import Form from '../../../Common/Form/Form';
import ScheduleForm from './ScheduleForm/ScheduleForm';
import { assembleMatrix } from './ScheduleForm/HourMatrix/HourMatrix';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';

export default function CreateSchedule() {
  const { studyId, questionnaireId } = useParams();
  const navigate = useNavigate();
  const {
    data: study,
    error,
    isLoading,
  } = useApiData({
    path: `/researcher/studies/${studyId}`,
  });
  const [formAlert, setFormAlert] = React.useState(null);

  React.useEffect(() => {
    if (study) {
      if (study.stage && study.stage === 'FINISHED') {
        navigate(`/researcher/studies/${studyId}`);
      }
    }
  }, [study, navigate, studyId]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>Something went wrong while fetching the study!</p>;
  }

  function isMatrixValid(data, matrix) {
    return matrix
      .map((row) => row.filter((hour) => hour === true).length)
      .every(
        (timeSlotCount) =>
          timeSlotCount >= data.minDailyNotifications || timeSlotCount === 0
      );
  }

  async function onSubmit(data) {
    try {
      const matrix = assembleMatrix(data, study.duration);

      if (!isMatrixValid(data, matrix)) {
        setFormAlert({
          severity: 'error',
          title: 'Schedule definition is invalid!',
          message: `The availability matrix is not valid. Please ensure that at least ${data.minDailyNotifications} slots are available each day, or decrease the minimum number of daily notifications. Alternatively you can disable notifications entirely for a specific day by disabling every timeslot for that day.`,
        });
        return;
      }

      const formData = {
        minDailyNotifications: data.minDailyNotifications,
        maxDailyNotifications: data.maxDailyNotifications,
        scheduleType: data.scheduleType,
      };

      if (data.scheduleType === 'REPEATED_PATTERN') {
        formData.repeatedPatternDuration = data.repeatedPatternDuration;
      }

      const scheduleJson = JSON.stringify({
        version: '1',
        data: {
          formData,
          matrix,
        },
      });
      await APIClient.post(
        `/researcher/studies/${studyId}/questionnaires/${questionnaireId}/schedules`,
        {
          name: data.name,
          scheduleJson,
        }
      );
      setFormAlert({
        severity: 'success',
        title: 'Schedule created!',
        message: 'Your schedule has been created successfully.',
      });
    } catch (e) {
      setFormAlert({
        severity: 'error',
        title: 'Could not create schedule!',
        message:
          'Something went wrong during schedule creation. Please contact the administrator for further support.',
      });
    }
  }

  return (
    <div className={styles.createSchedule}>
      <p className={styles.description}>
        Create a new schedule for the selected questionnaire using the form
        below.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <ScheduleForm study={study} />
        <div className={styles.formControls}>
          <Button variant="contained" type="submit" fullWidth>
            Create
          </Button>
        </div>
        {formAlert && (
          <div className={styles.alert}>
            <Alert severity={formAlert.severity}>
              {formAlert.title && <AlertTitle>{formAlert.title}</AlertTitle>}
              {formAlert.message}
            </Alert>
          </div>
        )}
      </Form>
    </div>
  );
}
