import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import Form from '../../../Common/Form/Form';
import styles from './CreateStudy.module.scss';
import APIClient from '../../../../../util/APIClient';
import StudyForm from './StudyForm/StudyForm';

export default function CreateStudy() {
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = React.useState(null);

  async function onSubmit(data) {
    const studyData = {
      name: data.name,
      description: data.description,
      duration: data.duration,
    };

    if (data.startingDayOfTheWeek) {
      studyData.startingDayOfTheWeek = data.startingDayOfTheWeek;
    }

    try {
      const result = await APIClient.post('/researcher/studies', studyData);
      setFormAlert({
        severity: 'success',
        title: 'Study created!',
        message:
          'Your study has been created successfully. You will be redirected shortly.',
      });
      setTimeout(() => {
        navigate(`/researcher/studies/${result.data.id}`);
      }, 3000);
    } catch (e) {
      setFormAlert({
        severity: 'error',
        title: 'Could not create study!',
        message:
          'Something went wrong during study creation. Please contact the administrator for further support.',
      });
    }
  }

  return (
    <div className={styles.createStudy}>
      <p className={styles.description}>
        Create a new study using the form below.
      </p>
      <p className={styles.description}>
        Once created, you will be able to invite additional researchers to the
        study and add questionnaires and schedules.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <StudyForm />
        <div className={styles.formControls}>
          <Button variant="contained" type="submit" fullWidth>
            Create
          </Button>
        </div>
        {formAlert && (
          <div className={styles.alert}>
            <Alert severity={formAlert.severity}>
              {formAlert.title && <AlertTitle>{formAlert.title}</AlertTitle>}
              {formAlert.message}
            </Alert>
          </div>
        )}
      </Form>
    </div>
  );
}
