import React from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import styles from './Answer.module.scss';

export default function Answer(props) {
  const { questionNextQuestion, questions, answer } = props;

  let nextQuestion = questionNextQuestion;
  if (answer.nextQuestionId) {
    const matchingNextQuestions = questions.filter(
      (q) => q.id === answer.nextQuestionId
    );
    if (matchingNextQuestions.length === 1) {
      nextQuestion = matchingNextQuestions[0];
    }
  }

  return (
    <div className={styles.answer}>
      <table>
        <tbody>
          <tr>
            <td className={styles.icon}>
              <RadioButtonUncheckedIcon />
            </td>
            <td className={styles.value}>
              <p>{answer.content}</p>
            </td>

            {(nextQuestion && nextQuestion.prompt && (
              <>
                <td className={styles.icon}>
                  <a href={`#${nextQuestion.id}`}>
                    <ContentPasteSearchIcon />
                  </a>
                </td>
                <td className={styles.nextQuestion}>
                  <a href={`#${nextQuestion.id}`}>{nextQuestion.prompt}</a>
                </td>
              </>
            )) || (
              <>
                <td className={`${styles.questionnaireEnds}`}>
                  Questionnaire ends after this answer
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
