import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import styles from './QuestionnairePreview.module.scss';
import Question from './Question/Question';
import NotificationPrompt from './NotificationPrompt/NotificationPrompt';
import APIClient from '../../../../../../util/APIClient';

export default function QuestionnairePreview(props) {
  const { questionnaire, study } = props;
  const navigate = useNavigate();
  questionnaire.questions.sort((a, b) => a.orderNumber - b.orderNumber);

  async function deleteQuestionnaire() {
    await APIClient.delete(
      `/researcher/studies/${study.id}/questionnaires/${questionnaire.id}`
    );
    navigate(`/researcher/studies/${study.id}/questionnaires`);
  }

  // function editQuestionnaire() {
  //   navigate(
  //     `/researcher/studies/${study.id}/questionnaires/${questionnaire.id}/edit`
  //   );
  // }

  return (
    <>
      <div className={styles.questionnaireInfo}>
        <div className={styles.topRow}>
          <h1>{questionnaire.name}</h1>
          <div className={styles.buttons}>
            <Button
              color="error"
              variant="outlined"
              onClick={() => deleteQuestionnaire()}
              disabled={['IN_PROGRESS', 'FINISHED'].includes(study.stage)}
            >
              Delete
            </Button>
            {/* <Button variant="outlined" onClick={() => editQuestionnaire()}> */}
            {/*  Edit */}
            {/* </Button> */}
          </div>
        </div>
        <p className={styles.description}>{questionnaire.description}</p>
        <div className={styles.questionnaireOption}>
          <div className={styles.option}>First question:</div>
          <div className={styles.value}>
            <div className={styles.icon}>
              <ContentPasteSearchIcon />
            </div>
            <a href={`#${questionnaire.rootQuestionId}`}>
              {
                questionnaire.questions.filter(
                  (q) => q.id === questionnaire.rootQuestionId
                )[0].prompt
              }
            </a>
          </div>
        </div>
        <div className={styles.questionnaireOption}>
          <div className={styles.option}>Allow event-driven submission:</div>
          <div className={styles.value}>
            {questionnaire.allowEventDriven ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <div className={styles.notificationPrompts}>
        <h1 className={styles.sectionHeader}>Notification prompts</h1>
        {questionnaire.notificationPrompts.map((notificationPrompt) => (
          <NotificationPrompt
            key={notificationPrompt.id}
            notificationPrompt={notificationPrompt}
          />
        ))}
      </div>
      <div className={styles.questions}>
        <h1 className={styles.sectionHeader}>Questions</h1>
        {questionnaire.questions.map((question) => (
          <Question
            key={question.id}
            question={question}
            questions={questionnaire.questions}
          />
        ))}
      </div>
    </>
  );
}
