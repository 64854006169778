import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table, {
  STUDY_STAGE_COLUMN_ENUM,
} from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function Studies() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useApiData({
    path: '/researcher/studies',
    params: {
      includeResearcherCount: true,
      includeParticipantCount: true,
      includeQuestionnaireCount: true,
    },
  });

  function createNewStudy() {
    navigate('create');
  }

  if (isLoading) {
    return <Loading message="Fetching studies..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <Table
      rows={data}
      topButtons={[
        {
          label: 'Create study',
          handler: () => {
            createNewStudy();
          },
        },
      ]}
      title="Studies"
      rowLinks={{
        name: {
          label: 'View study',
          to: (id) => `/researcher/studies/${id}`,
        },
        researcherCount: {
          label: 'View researchers',
          to: (id) => `/researcher/studies/${id}/researchers`,
        },
        participantCount: {
          label: 'View participants',
          to: (id) => `/researcher/studies/${id}/participants`,
        },
        questionnaireCount: {
          label: 'View questionnaires',
          to: (id) => `/researcher/studies/${id}/questionnaires`,
        },
      }}
      columns={[
        {
          id: 'name',
          label: 'Study name',
          numeric: false,
        },
        {
          id: 'stage',
          label: 'Stage',
          numeric: false,
          enum: STUDY_STAGE_COLUMN_ENUM,
        },
        {
          id: 'researcherCount',
          label: 'Researchers',
          numeric: true,
        },
        {
          id: 'participantCount',
          label: 'Participants',
          numeric: true,
        },
        {
          id: 'questionnaireCount',
          label: 'Questionnaires',
          numeric: true,
        },
        {
          id: 'createdAt',
          label: 'Created on',
          numeric: true,
          date: true,
        },
      ]}
    />
  );
}
