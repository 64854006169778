import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './Question.module.scss';
import Answer from './Answer/Answer';
import useQuestionnaire from '../../../../../../../hooks/useQuestionnaire';
import FormSelect from '../../../../../Common/FormSelect/FormSelect';
import FormTextField from '../../../../../Common/FormTextField/FormTextField';
import FormCustomValidationGroup from '../../../../../Common/FormCustomValidationGroup/FormCustomValidationGroup';
import {
  fieldCountValidator,
  lowerOrEqualNumberValidator,
} from '../../../../../../../util/FormValidatorFunctions';
import Menu from '../../../../../Common/Menu/Menu';

export default function Question(props) {
  const { getValues, setValue, unregister } = useFormContext();

  const {
    questions,
    addAnswer,
    setDefaultAnswers,
    removeQuestion,
    shiftQuestion,
    getQuestionTypeOptions,
    getEndQuestionnaireOption,
    removeAllAnswers,
    updateQuestionLocalIdentifier,
  } = useQuestionnaire();

  const { question, orderNumber, isFirstQuestion, isLastQuestion } = props;
  const { id, answers, localIdentifier } = question;

  const otherQuestions = React.useMemo(
    () => questions.filter((q) => q.id !== id),
    [id, questions]
  );
  const [nextQuestionOptions, setNextQuestionOptions] = React.useState([]);
  const questionTypeOptions = React.useMemo(
    () => getQuestionTypeOptions(id),
    [getQuestionTypeOptions, id]
  );
  const endQuestionnaireOption = React.useMemo(
    () => getEndQuestionnaireOption(id),
    [getEndQuestionnaireOption, id]
  );

  const watchedLocalIdentifier = useWatch({
    name: `questions.${id}.localIdentifier`,
    defaultValue: question.localIdentifier,
  });

  React.useEffect(() => {
    updateQuestionLocalIdentifier(id, watchedLocalIdentifier);
  }, [id, watchedLocalIdentifier, updateQuestionLocalIdentifier]);

  React.useEffect(() => {
    const questionLabels = getValues(
      otherQuestions.map((q) => `questions.${q.id}.localIdentifier`)
    );
    const newNextQuestionOptions = questionLabels.map(
      (questionLocalIdentifier, index) => ({
        key: `question-${id}-nextQuestionOrderIdOption-${otherQuestions[index].id}`,
        value: otherQuestions[index].id,
        label: questionLocalIdentifier,
      })
    );
    setNextQuestionOptions([...newNextQuestionOptions, endQuestionnaireOption]);
  }, [endQuestionnaireOption, getValues, id, otherQuestions]);

  const defaultQuestionTypeOption = questionTypeOptions[0];
  const selectedQuestionType = useWatch({
    name: `questions.${id}.type`,
    defaultValue: defaultQuestionTypeOption.value,
  });

  const numberConstraintsValidator = lowerOrEqualNumberValidator(
    getValues,
    `questions.${id}.numberConstraintsMin`,
    `questions.${id}.numberConstraintsMax`
  );

  React.useEffect(() => {
    if (['TEXT', 'NUMBER'].includes(selectedQuestionType)) {
      removeAllAnswers(id, unregister);
    }
    if (['TEXT', 'PREDEFINED_ANSWER'].includes(selectedQuestionType)) {
      [
        `questions.${id}.numberConstraintsMin`,
        `questions.${id}.numberConstraintsMax`,
      ].forEach((field) => {
        setValue(field, undefined);
      });
    }
    if (selectedQuestionType === 'PREDEFINED_ANSWER') {
      setValue(`questions.${id}.placeholder`, undefined);
      const defaultAnswerIds = setDefaultAnswers(id);
      defaultAnswerIds.map((answerId, index) =>
        setValue(
          `questions.${id}.answers.${answerId}.content`,
          `Option ${index + 1}`
        )
      );
    }
  }, [
    selectedQuestionType,
    id,
    setValue,
    removeAllAnswers,
    setDefaultAnswers,
    unregister,
  ]);

  React.useEffect(() => {
    setValue(`questions.${id}.orderNumber`, orderNumber);
  }, [id, setValue, orderNumber]);

  const questionMenuItems = [
    {
      label: 'Move up',
      disabled: isFirstQuestion,
      handler: () => shiftQuestion(id, false),
    },
    {
      label: 'Move down',
      disabled: isLastQuestion,
      handler: () => shiftQuestion(id, true),
    },
  ];

  return (
    <div className={styles.question}>
      <div className={styles.hiddenInput}>
        <FormTextField
          name={`questions.${id}.orderNumber`}
          defaultValue={orderNumber}
        />
      </div>
      <div className={`${styles.row} ${styles.topRow}`}>
        <div className={styles.topField}>
          <FormTextField
            name={`questions.${id}.localIdentifier`}
            defaultValue={localIdentifier}
            autocomplete="off"
            label="Local identifier"
            variant="standard"
            size="small"
          />
        </div>
        <div className={styles.topField}>
          <FormSelect
            name={`questions.${id}.type`}
            variant="standard"
            size="small"
            label={' '}
            options={questionTypeOptions}
            defaultOption={defaultQuestionTypeOption}
          />
        </div>
        <div className={styles.buttons}>
          <Menu id={`question-${id}`} items={questionMenuItems} />
          <IconButton onClick={() => removeQuestion(id, unregister)}>
            <ClearIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.row}>
        <div
          className={
            selectedQuestionType === 'PREDEFINED_ANSWER'
              ? styles.fullWidthField
              : styles.field
          }
        >
          <FormTextField
            name={`questions.${id}.prompt`}
            label="Prompt"
            defaultValue=""
            autocomplete="off"
            defaultHelperText="The actual question participants will see and answer."
            multiline
            rules={{
              required: {
                value: true,
                message: 'Question prompt is required.',
              },
              minLength: {
                value: 1,
                message: 'The prompt cannot be shorter than 1 character.',
              },
              maxLength: {
                value: 250,
                message: 'The prompt cannot be longer than 250 characters.',
              },
            }}
          />
        </div>
        {selectedQuestionType !== 'PREDEFINED_ANSWER' && (
          <>
            <div className={styles.arrowSeparator}>
              <ArrowForwardIcon />
            </div>
            <div className={styles.field}>
              <FormSelect
                name={`questions.${id}.nextQuestionOrderId`}
                label="Next question"
                options={nextQuestionOptions}
                defaultHelperText="Next question that will be displayed upon answering."
                rules={{
                  required: {
                    value: true,
                    message: 'Next question has to be specified.',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
      {['TEXT', 'NUMBER'].includes(selectedQuestionType) && (
        <div className={styles.row}>
          <div className={`${styles.fullWidthField} ${styles.skipTopMargin}`}>
            <FormTextField
              name={`questions.${id}.placeholder`}
              label="Placeholder"
              defaultValue=""
              defaultHelperText="Placeholder will be displayed in the answer field before participants enter their answers."
              autocomplete="off"
            />
          </div>
        </div>
      )}
      {selectedQuestionType === 'NUMBER' && (
        <div className={styles.row}>
          <FormCustomValidationGroup
            className={styles.numberConstraints}
            name="numberConstraintsValidator"
            fieldsToWatch={[
              `questions.${id}.numberConstraintsMin`,
              `questions.${id}.numberConstraintsMax`,
            ]}
            customValidatorError="Minimum cannot be greater than the maximum."
            customValidator={() => numberConstraintsValidator()}
          >
            <div className={styles.horizontalInputs}>
              <FormTextField
                name={`questions.${id}.numberConstraintsMin`}
                label="Optional minimum"
                defaultHelperText="Optional minimum value for a numerical answer."
                autocomplete="off"
                type="number"
                rules={{
                  validate: {
                    numberConstraintsValidator,
                  },
                }}
              />
              <div className={styles.separator} />
              <FormTextField
                name={`questions.${id}.numberConstraintsMax`}
                label="Optional maximum"
                defaultHelperText="Optional maximum value for a numerical answer."
                autocomplete="off"
                type="number"
                rules={{
                  validate: {
                    numberConstraintsValidator,
                  },
                }}
              />
            </div>
          </FormCustomValidationGroup>
        </div>
      )}
      {selectedQuestionType === 'PREDEFINED_ANSWER' && (
        <div className={styles.answers}>
          {answers.map((answer, index, array) => (
            <Answer
              key={answer.id}
              id={answer.id}
              orderNumber={`${index}`}
              questionId={id}
              questionNextQuestionOptions={nextQuestionOptions}
              isFirstAnswer={index === 0}
              isLastAnswer={index === array.length - 1}
            />
          ))}

          <FormCustomValidationGroup
            className={styles.answersValidator}
            name="answersValidator"
            customValidatorError="At least two answers are required for a question with predefined answers."
            customValidator={() =>
              fieldCountValidator(
                answers.map((answer) => `questions.${id}.answers.${answer.id}`),
                2
              )
            }
            fieldsToWatch={[]}
          />
          <div className={styles.addAnswer}>
            <Button
              type="button"
              onClick={() => {
                const answerId = addAnswer(id);
                setValue(
                  `questions.${id}.answers.${answerId}.content`,
                  `Option ${answers.length + 1}`
                );
              }}
              startIcon={<AddIcon />}
            >
              Add answer
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
