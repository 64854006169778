import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EnumElement from '../../../Common/Table/EnumElement/EnumElement';
import { STUDY_STAGE_COLUMN_ENUM } from '../../../Common/Table/Table/Table';
import styles from './Study.module.scss';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import APIClient from '../../../../../util/APIClient';
import Menu from '../../../Common/Menu/Menu';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

const ORDERED_STUDY_STAGES = [
  'SETUP',
  'RECRUITMENT',
  'IN_PROGRESS',
  'FINISHED',
];

const STUDY_STAGE_COLUMN_ENUM_ALL_GRAY = {};
Object.keys(STUDY_STAGE_COLUMN_ENUM).forEach((key) => {
  STUDY_STAGE_COLUMN_ENUM_ALL_GRAY[key] = {
    ...STUDY_STAGE_COLUMN_ENUM[key],
    colors: {
      background: '#d8d8d8',
      text: '#666666',
    },
  };
});

export default function Study() {
  const { studyId } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading, reload } = useApiData({
    path: `/researcher/studies/${studyId}`,
    params: {
      includeParticipantCount: true,
      includeResearcherCount: true,
      includeQuestionnaireCount: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching study..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  async function advanceToStage(targetStage) {
    await APIClient.patch(`/researcher/studies/${studyId}`, {
      stage: targetStage,
    });
    reload();
  }

  const currentStageIndex = ORDERED_STUDY_STAGES.indexOf(data.stage);
  const isStudyFinished = data.stage === STUDY_STAGE_COLUMN_ENUM.FINISHED.value;
  const hasNextStage = !isStudyFinished && currentStageIndex !== -1;
  const nextStage = hasNextStage
    ? ORDERED_STUDY_STAGES[currentStageIndex + 1]
    : null;
  const nextStageText = hasNextStage
    ? `Advance to "${STUDY_STAGE_COLUMN_ENUM[nextStage].value}"`
    : '';

  const menuItems = [];
  if (!isStudyFinished) {
    menuItems.push({
      label: 'Invite participant',
      handler: () => navigate(`participants/invite`),
    });
    menuItems.push({
      label: 'Finish study',
      handler: () => advanceToStage(STUDY_STAGE_COLUMN_ENUM.FINISHED.value),
    });
    menuItems.push({
      label: 'Export study data',
      handler: () => navigate('results'),
    });
  }

  return (
    <div className={styles.study}>
      <div className={styles.header}>
        <h1>{data.name}</h1>
        {data.stage === 'FINISHED' && (
          <Button
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() => navigate('results')}
          >
            Export data
          </Button>
        )}
        {menuItems.length > 0 && <Menu id={studyId} items={menuItems} />}
      </div>

      <div className={styles.description}>
        <p>{data.description}</p>
      </div>

      <div className={styles.stages}>
        <div className={styles.stageRow}>
          {ORDERED_STUDY_STAGES.map((stage, index) => (
            <div className={styles.stage} key={`stage-${stage}`}>
              <EnumElement
                enumObject={
                  index === currentStageIndex
                    ? STUDY_STAGE_COLUMN_ENUM
                    : STUDY_STAGE_COLUMN_ENUM_ALL_GRAY
                }
                value={stage}
              />
              {index < ORDERED_STUDY_STAGES.length - 1 && (
                <div className={styles.stageSeparator}>
                  <ChevronRightRoundedIcon />
                </div>
              )}
            </div>
          ))}
        </div>
        {hasNextStage && (
          <Button variant="outlined" onClick={() => advanceToStage(nextStage)}>
            {nextStageText}
          </Button>
        )}
      </div>

      <div className={styles.tiles}>
        <Link to="participants" className={styles.tile}>
          <h3>Invited participants</h3>
          <p>{data.participantCount}</p>
        </Link>
        <Link to="researchers" className={styles.tile}>
          <h3>Researchers</h3>
          <p>{data.researcherCount}</p>
        </Link>
        <Link to="questionnaires" className={styles.tile}>
          <h3>Questionnaires</h3>
          <p>{data.questionnaireCount}</p>
        </Link>
        <div className={styles.tile}>
          <h3>Study duration</h3>
          <p>{data.duration} days</p>
        </div>
        <div className={styles.tile}>
          <h3>Starting day of the week</h3>
          <p>
            {data.startingDayOfTheWeek
              ? `${data.startingDayOfTheWeek[0].toUpperCase()}${data.startingDayOfTheWeek.substring(
                  1
                )}`
              : 'Not specified'}
          </p>
        </div>
      </div>
    </div>
  );
}
