import React from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import styles from './Question.module.scss';
import Answer from './Answer/Answer';
import { QUESTION_TYPE_OPTIONS } from '../../../../../../../hooks/useQuestionnaire';

export default function Question(props) {
  const { question, questions } = props;

  const notSpecified = <em>Not specified</em>;

  let questionType = { value: 'UNKNOWN', label: 'Unknown type' };
  const matchingQuestionTypes = QUESTION_TYPE_OPTIONS.filter(
    (option) => option.value === question.type
  );
  if (matchingQuestionTypes.length === 1) {
    questionType = matchingQuestionTypes[0];
  }

  let nextQuestion = null;
  if (question.nextQuestionId) {
    const matchingNextQuestions = questions.filter(
      (q) => q.id === question.nextQuestionId
    );
    if (matchingNextQuestions.length === 1) {
      nextQuestion = matchingNextQuestions[0];
    }
  }

  const parameters = [];

  if (['TEXT', 'NUMBER'].includes(questionType.value)) {
    parameters.push({
      key: 'Placeholder',
      value: question.placeholder ? question.placeholder : notSpecified,
    });
  }
  if (questionType.value === 'NUMBER') {
    parameters.push({
      key: 'Minimum value',
      value: question.numberConstraintsMin
        ? question.numberConstraintsMin
        : notSpecified,
    });
  }
  if (questionType.value === 'NUMBER') {
    parameters.push({
      key: 'Maximum value',
      value: question.numberConstraintsMax
        ? question.numberConstraintsMax
        : notSpecified,
    });
  }

  return (
    <div className={styles.question} id={question.id}>
      <div className={styles.header}>
        <h1>{question.prompt}</h1>
        <p>({questionType.label})</p>
      </div>
      <div className={styles.content}>
        {parameters.length > 0 && (
          <div className={styles.parameters}>
            <table>
              <tbody>
                {parameters.map((parameter) => (
                  <tr key={parameter.key}>
                    <td>{parameter.key}</td>
                    <td>{parameter.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {question.questionAnswers.length > 0 && (
          <div className={styles.answers}>
            {question.questionAnswers.map((answer) => (
              <Answer
                key={answer.id}
                answer={answer}
                questions={questions}
                questionNextQuestion={nextQuestion}
              />
            ))}
          </div>
        )}
      </div>

      {questionType.value !== 'PREDEFINED_ANSWER' && (
        <>
          {(nextQuestion && (
            <a href={`#${nextQuestion.id}`} className={styles.footer}>
              <div className={styles.icon}>
                <ContentPasteSearchIcon />
              </div>
              <h2>Next question:</h2>
              <p>{nextQuestion.prompt}</p>
            </a>
          )) || (
            <div className={styles.footer}>
              <p className={styles.questionnaireEnds}>
                Questionnaire ends after this question
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
