import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Administrators from '../Users/Administrators/Administrators';
import Researchers from '../Users/Researchers/Researchers';
import Participants from '../Users/Participants/Participants';
import Studies from '../Studies/Studies/Studies';
import StudyResearchers from '../Studies/StudyResearchers/StudyResearchers';
import InviteAdministrator from '../Users/Administrators/InviteAdministrator/InviteAdministrator';
import InviteResearcher from '../Users/Researchers/InviteResearcher/InviteResearcher';
import NotFound from '../../Common/NotFound/NotFound';
import AuthenticatedAdminRoute from '../../../_functional/AuthenticatedAdminRoute';

function AdministratorLayout() {
  return (
    <Routes>
      <Route index element={<Navigate to="studies" />} />
      <Route path="studies/*">
        <Route index element={<Studies />} />
        <Route path=":studyId/*">
          <Route index element={<Navigate to="researchers" />} />
          <Route path="researchers" element={<StudyResearchers />} />
        </Route>
      </Route>
      <Route path="users/*">
        <Route index element={<Navigate to="administrators" />} />
        <Route path="administrators/*">
          <Route index element={<Administrators />} />
          <Route index path="invite" element={<InviteAdministrator />} />
        </Route>
        <Route index element={<Navigate to="researchers" />} />
        <Route path="researchers/*">
          <Route index element={<Researchers />} />
          <Route index path="invite" element={<InviteResearcher />} />
        </Route>
        <Route path="participants" element={<Participants />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AuthenticatedAdminRoute(AdministratorLayout);
