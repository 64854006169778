import React from 'react';
import logo from '../../../../../assets/images/mesmerise_logo_banner.svg';
import styles from './NavbarHeader.module.scss';
import useAuth from '../../../../../hooks/useAuth';

export default function NavbarHeader() {
  const { credentials, isAdministrator, isResearcher } = useAuth();
  const version = process.env.REACT_APP_VERSION || null;
  const roles = [];
  if (credentials) {
    if (isAdministrator) {
      roles.push('Administrator');
    }
    if (isResearcher) {
      roles.push('Researcher');
    }
  }

  const role = roles.join(', ');

  return (
    <div className={styles.navbarHeader}>
      <img src={logo} alt="Logo" />
      {version && (
        <div className={styles.row}>
          <p className={styles.header}>App version:</p>
          <p className={styles.value}>{version}</p>
        </div>
      )}
      {role && (
        <div className={styles.row}>
          <p className={styles.header}>Role{roles.length > 1 ? 's' : ''}:</p>
          <p className={styles.value}>{role}</p>
        </div>
      )}
    </div>
  );
}
