import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useController, useFormContext } from 'react-hook-form';
import styles from './HourCheckbox.module.scss';

export default function HourCheckbox(props) {
  const { defaultValue, onClick } = props;
  const { control } = useFormContext();
  const useControllerProps = {
    ...props,
    control,
    defaultValue: defaultValue || false,
  };

  const {
    field: { onChange: controlledOnChange, onBlur, name, value, ref },
  } = useController(useControllerProps);

  const onChange = (args) => {
    onClick();
    controlledOnChange(args);
  };

  return (
    <Checkbox
      name={name}
      inputRef={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      control={control}
      className={styles.hourCheckbox}
      checked={value}
      checkedIcon={<div className={styles.checked} />}
      icon={<div className={styles.unchecked} />}
    />
  );
}
