import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function Researchers() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useApiData({
    path: '/researcher/users/groups/researchers',
  });

  function inviteResearcher() {
    navigate('invite');
  }

  if (isLoading) {
    return <Loading message="Fetching researchers..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <Table
      rows={data}
      topButtons={[
        {
          label: 'Invite researcher',
          handler: () => {
            inviteResearcher();
          },
        },
      ]}
      title="Researchers"
      columns={[
        {
          id: 'givenName',
          label: 'First name',
          numeric: false,
        },
        {
          id: 'familyName',
          label: 'Last name',
          numeric: false,
        },
        {
          id: 'email',
          label: 'E-mail address',
          numeric: false,
        },
      ]}
    />
  );
}
