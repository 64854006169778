import React from 'react';
import styles from './StudyResultsForm.module.scss';
import FormSelect from '../../../../Common/FormSelect/FormSelect';
import FormCheckbox from '../../../../Common/FormCheckbox/FormCheckbox';

export default function StudyResultsForm() {
  const formatOptions = ['CSV', 'JSON'].map((day) => ({
    key: day.toLowerCase(),
    label: `.${day}`,
    value: day.toLowerCase(),
  }));

  return (
    <>
      <FormCheckbox
        name="includeParticipantDetails"
        label="include participant details"
        defaultHelperText="Participant details includes information such as names and e-mail addresses, which constitutes personally identifiable information and should be treated with special care."
        autocomplete="off"
      />
      <div className={styles.select}>
        <FormSelect
          name="format"
          label="File format"
          options={formatOptions}
          defaultOption={formatOptions[0]}
          defaultHelperText="Exporting the data as CSV will produce a lot of redundant information but will be easier to work with in spreadsheets; exporting the data in JSON will provide a much more convenient and efficient format to work with in code environments."
        />
      </div>
    </>
  );
}
