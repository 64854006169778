import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Table, {
  STUDY_STAGE_COLUMN_ENUM,
} from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function Studies() {
  const [searchParams] = useSearchParams();
  const researcherId = searchParams.get('researcherId') || null;
  const leadResearcher = searchParams.get('leadResearcher') || null;

  const { data, error, isLoading } = useApiData({
    path: '/administrator/studies',
    params: {
      includeResearchers: true,
      includeResearcherCount: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching studies..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  let displayedData = data;
  if (researcherId) {
    if (leadResearcher) {
      displayedData = displayedData.filter((study) =>
        study.researchers
          .filter((researcher) => researcher.isLead)
          .map((researcher) => researcher.cognitoId)
          .includes(researcherId)
      );
    } else {
      displayedData = displayedData.filter((study) =>
        study.researchers
          .map((researcher) => researcher.cognitoId)
          .includes(researcherId)
      );
    }
  }

  return (
    <Table
      rows={displayedData}
      title="Studies"
      rowLinks={{
        researcherCount: {
          label: 'View researchers',
          to: (id) => `/administrator/studies/${id}/researchers`,
        },
      }}
      columns={[
        {
          id: 'name',
          label: 'Study name',
          numeric: false,
        },
        {
          id: 'stage',
          label: 'Stage',
          numeric: false,
          enum: STUDY_STAGE_COLUMN_ENUM,
        },
        {
          id: 'researcherCount',
          label: 'Researchers',
          numeric: true,
        },
        {
          id: 'createdAt',
          label: 'Created on',
          numeric: true,
          date: true,
        },
      ]}
    />
  );
}
