import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useFormContext } from 'react-hook-form';
import styles from './Answer.module.scss';
import useQuestionnaire from '../../../../../../../../hooks/useQuestionnaire';
import FormSelect from '../../../../../../Common/FormSelect/FormSelect';
import FormTextField from '../../../../../../Common/FormTextField/FormTextField';
import Menu from '../../../../../../Common/Menu/Menu';

export default function Answer(props) {
  const { setValue, unregister } = useFormContext();
  const { removeAnswer, shiftAnswer } = useQuestionnaire();
  const {
    id,
    questionId,
    questionNextQuestionOptions,
    isFirstAnswer,
    isLastAnswer,
    orderNumber,
  } = props;

  const existingOptions = questionNextQuestionOptions;
  const nextQuestionOptions = [];

  nextQuestionOptions.push(
    ...existingOptions.map((option) => ({
      ...option,
      key: `question-${questionId}-answer-${id}-nextQuestionOrderIdOption-${option.value}`,
    }))
  );

  React.useEffect(() => {
    setValue(`questions.${questionId}.answers.${id}.orderNumber`, orderNumber);
  }, [questionId, id, setValue, orderNumber]);

  return (
    <div className={styles.answer}>
      <div className={styles.hiddenInput}>
        <FormTextField
          name={`questions.${questionId}.answers.${id}.orderNumber`}
          defaultValue={orderNumber}
        />
      </div>
      <div className={styles.radioIcon}>
        <RadioButtonUncheckedIcon />
      </div>
      <div className={styles.optionField}>
        <FormTextField
          name={`questions.${questionId}.answers.${id}.content`}
          label="Answer"
          defaultHelperText="Option participants will be able to select."
          autocomplete="off"
          rules={{
            required: {
              value: true,
              message: 'Answer content is required.',
            },
            minLength: {
              value: 1,
              message: 'The answer cannot be shorter than 1 character.',
            },
            maxLength: {
              value: 250,
              message: 'The answer cannot be longer than 250 characters.',
            },
          }}
        />
      </div>
      <div className={styles.arrowSeparator}>
        <ArrowForwardIcon />
      </div>
      <div className={styles.nextQuestionField}>
        <FormSelect
          name={`questions.${questionId}.answers.${id}.nextQuestionOrderId`}
          label="Next question"
          defaultHelperText="Next question for this answer."
          options={nextQuestionOptions}
          rules={{
            required: {
              value: true,
              message: 'Next question has to be specified.',
            },
          }}
        />
      </div>
      <div className={styles.buttons}>
        <Menu
          items={[
            {
              label: 'Move up',
              handler: () => shiftAnswer(questionId, id, false),
              disabled: isFirstAnswer,
            },
            {
              label: 'Move down',
              handler: () => shiftAnswer(questionId, id, true),
              disabled: isLastAnswer,
            },
          ]}
        />
        <IconButton onClick={() => removeAnswer(questionId, id, unregister)}>
          <ClearIcon />
        </IconButton>
      </div>
    </div>
  );
}
