import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Study from '../Studies/Study/Study';
import Studies from '../Studies/Studies/Studies';
import CreateStudy from '../Studies/CreateStudy/CreateStudy';
import Researchers from '../Users/Researchers/Researchers';
import InviteResearcher from '../Users/Researchers/InviteResearcher/InviteResearcher';
import StudyResearchers from '../Studies/StudyResearchers/StudyResearchers/StudyResearchers';
import InviteStudyResearcher from '../Studies/StudyResearchers/InviteStudyResearcher/InviteStudyResearcher';
import StudyParticipants from '../Studies/StudyParticipants/StudyParticipants/StudyParticipants';
import InviteStudyParticipant from '../Studies/StudyParticipants/InviteStudyParticipant/InviteStudyParticipant';
import StudyQuestionnaires from '../Studies/StudyQuestionnaires/StudyQuestionnaires';
import StudySchedules from '../Studies/StudySchedules/StudySchedules';
import CreateSchedule from '../Studies/CreateSchedule/CreateSchedule';
import CreateQuestionnaire from '../Studies/CreateQuestionnaire/CreateQuestionnaire';
import Questionnaire from '../Studies/Questionnaire/Questionnaire';
import StudySchedule from '../Studies/StudySchedules/StudySchedule/StudySchedule';
import NotFound from '../../Common/NotFound/NotFound';
import AuthenticatedResearcherRoute from '../../../_functional/AuthenticatedResearcherRoute';
import StudyResults from '../Studies/StudyResults/StudyResults';

function ResearcherLayout() {
  return (
    <Routes>
      <Route index element={<Navigate to="studies" />} />
      <Route path="studies/*">
        <Route index element={<Studies />} />
        <Route path="create" element={<CreateStudy />} />
        <Route path=":studyId/*">
          <Route index element={<Study />} />
          <Route path="results" element={<StudyResults />} />
          <Route path="researchers/*">
            <Route index element={<StudyResearchers />} />
            <Route path="invite" element={<InviteStudyResearcher />} />
          </Route>
          <Route path="participants/*">
            <Route index element={<StudyParticipants />} />
            <Route path="invite" element={<InviteStudyParticipant />} />
          </Route>
          <Route path="questionnaires/*">
            <Route index element={<StudyQuestionnaires />} />
            <Route path="create" element={<CreateQuestionnaire />} />
            <Route path=":questionnaireId/*">
              <Route index element={<Questionnaire />} />
              <Route path="schedules/*">
                <Route index element={<StudySchedules />} />
                <Route path=":scheduleId" element={<StudySchedule />} />
                <Route path="create" element={<CreateSchedule />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="users/*">
        <Route index element={<Navigate to="researchers" />} />
        <Route path="researchers/*">
          <Route index element={<Researchers />} />
          <Route index path="invite" element={<InviteResearcher />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AuthenticatedResearcherRoute(ResearcherLayout);
